import moment from "moment";
import { ITicket } from "./TicketsManagement";
import { ConvertZ } from "../../../components/helpers/helperFunctions";

export interface ICalendarItem {
    id: number|string;
    flag: "dueDate" | "holiday" | "openDate" | "actionDate" | "task" ;
    title: string;
    start: any;
    end: any;
    scheduleDateTime:any;
    isDraggable: boolean;
    isDroppable: boolean;
    isSelectable: boolean;
    hexColor: string;
    pColor: string;
    description: string;
    isActive: boolean;
    group: string;
    agent: string;
    ticketLogs:any;
    ticketId?:number
};

export const ticketsToEvents = (tickets:ITicket[]) =>{
    const modifiedTickets:any[] = [];
    tickets.map((i:ITicket)=>{
        let {group,agent,ticketStatus,title,description,dueDateTime,slaRemaining,priority,firstResponseDueDateTime} = i?.ticketLogs;
        if(ticketStatus?.name === "Closed" || !dueDateTime) return;
        // if(dueDateTime){ dueDateTime = ConvertZ(dueDateTime)}
        // if(firstResponseDueDateTime) {firstResponseDueDateTime = ConvertZ(firstResponseDueDateTime)}
        const tempObj:ICalendarItem =  {
            id : i?.id || 0,
            flag : "dueDate",
            title : title || "",
            start: moment(dueDateTime).subtract(30, "minutes").toDate(),
            end: moment(dueDateTime).toDate(),
            scheduleDateTime: moment(dueDateTime).subtract(30, "minutes").toDate(),
            // start: moment(dueDateTime).subtract(30, "minutes").toDate(),
            // end: new Date(dueDateTime),
            isDraggable: false,
            isDroppable: false,
            isSelectable: true,
            hexColor: slaRemaining && slaRemaining.includes("-") ? "#f2cdbb" : "#93e6bd",
            pColor: priority?.color || "#5558",
            description : description || "",
            isActive : true,
            group: group?.name || "-",
            agent: agent?.firstName ? `${agent.firstName} ${agent.lastName}` : "-" ,
            ticketLogs : i.ticketLogs
        }
        console.log("momoent_conversion",moment(dueDateTime).subtract(30, "minutes").toDate())
        console.log("momoent_conversion_firstResponseDueDateTime",moment(firstResponseDueDateTime).subtract(30, "minutes").toDate())
        modifiedTickets.push(tempObj);

        if(!firstResponseDueDateTime) {
            const tempObj2:ICalendarItem = {
                ...tempObj,
                flag:"actionDate",
                start: moment(firstResponseDueDateTime).subtract(30, "minutes").toDate(),
                end: moment(firstResponseDueDateTime).toDate(),
                scheduleDateTime: moment(firstResponseDueDateTime).subtract(30, "minutes").toDate(),
            }
            modifiedTickets.push(tempObj2);
        }
    })
    console.log("ticketsToEvents_modifiedTickets",modifiedTickets)
    return modifiedTickets;
}
//ticketTaskList

export const tasksToEvents = (tickets:ITicket[]) =>{
    const modifiedTickets:any[] = [];
    tickets.map((i:ITicket)=>{
        let {group,ticketStatus,agent,title,description,dueDateTime,ticketId} = i?.ticketTaskLogs || {};
        // if(dueDateTime){ dueDateTime = ConvertZ(dueDateTime)}
//        if(ticketStatus?.name === "Closed") return;
        if(ticketStatus?.name === "Closed" || !dueDateTime) return;

        
        const tempObj:ICalendarItem =  {
            id : i?.id || 0,
            flag : "task",
            ticketId : i?.ticketId || ticketId || 0,
            title : title || "",
            start: moment(dueDateTime).subtract(30, "minutes").toDate(),
            end: moment(dueDateTime).toDate(),
            scheduleDateTime: moment(dueDateTime).subtract(30, "minutes").toDate(),
            // start: dueDateTime && moment(dueDateTime).subtract(30, "minutes").toDate(),
            // end: dueDateTime && new Date(dueDateTime),
            // scheduleDateTime:dueDateTime && new Date(dueDateTime),
            isDraggable: false,
            isDroppable: false,
            isSelectable: true,
            hexColor: "#eac3f3",
            pColor: "#a2019d",
            description : description || "",
            isActive : true,
            group: group?.name || "-",
            agent: agent?.firstName ? `${agent.firstName} ${agent.lastName}` : "-" ,
            ticketLogs : i?.ticketTaskLogs
        }
        modifiedTickets.push(tempObj);
    })
    console.log("ticketsToEvents_modifiedTickets",modifiedTickets)
    return modifiedTickets;
}



export interface IstoreTicketPopoverInfoProps{
    ticketId : number;
    anchorEl : any;
}
