import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio

  indexAxis: 'x' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
//   responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
          font: {
              size: 10, // Adjust the font size here
          },
          padding: 5, // Adjust the padding
      },
  },
    title: {
      display: false,
      text: 'Chart.js Vertical Bar Chart',
    },
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 10, // Adjust the font size for x-axis ticks
        },
      },
    },
    y: {
      ticks: {
        font: {
          size: 10, // Adjust the font size for y-axis ticks
        },
      },
    },
  },
};


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [20,80],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [20,30],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};


const VerticalChart = ({
  id='myReportBar',
  label,
  dataSets,
  callback,
}: {
  label: any;
  dataSets: any;
  callback?: any;
  id?: any
})=>{
  const data = {
    labels: label,
    datasets: dataSets,
  };
  const onchange = () =>{
    console.log(callback)
    if (callback) callback()
  }
  React.useEffect(()=>{
    setTimeout(()=>{
      onchange()
    },100)
  },[label])
    return (
        <Bar
          height={400}
          data={data}
          options={options}
        />
    );
  }
  export default VerticalChart;