import axios from "axios"; 
import configs from "../../../configs/config";
const getTicketStatusUrl = `${configs.BASE_URL}/Dashboard/v2`;
const getTicketTrendsUrl= `${configs.BASE_URL}/Dashboard/tickettrends`;
const getResponseOrResolutionBySlaUrl= `${configs.BASE_URL}/Dashboard/ticket/response-resolution`;
export const getDashboardDataUrl = `${getTicketStatusUrl}?ticketDashboardFilter=0`
export const userGroupInfoUrl = `${configs.BASE_URL}/Dashboard/usergroup
`;
  export const getTicketStatus = async (ticketDashboardFilter: number) => {
    try {
        const response = await axios.get(`${getTicketStatusUrl}?ticketDashboardFilter=${ticketDashboardFilter}`);
        const userGroupInfo = await axios.get(`${userGroupInfoUrl}`);
        response.data.group = userGroupInfo.data.group;
        response.data.resolved = response.data.ticketStatus.resolvedInPercentage;
        response.data.unResolved = response.data.ticketStatus.unResolvedInPercentage;
        response.data.responseSlaCount = response.data.responseSlainpercentage;
        response.data.resolutionSlaCount = response.data.resolutionSlainpercentage;
        response.data.ticketStatus =  response.data.ticketStatus.dataList;
        return response
      } catch (error) {
        return false
      }
  };

  export const getTicketTrends = async () => {
    try {
        const response = await axios.get(`${getTicketTrendsUrl}`);
        return response
      } catch (error) {
        return false
      }
  };
  export const getResponseOrResolutionBySla = async () => {
    try {
        const response = await axios.get(getResponseOrResolutionBySlaUrl);
        return response
      } catch (error) {
        return false
      }
  };

  export default getTicketStatus