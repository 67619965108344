import React, { useEffect, useRef, useState } from "react";
import CommonStyles from "../common/CommonStyles";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./ReportChart";
import {
  lineChartOptionsData,
  barChartOptionsData,
  columnChartOptionsData,
} from "./ReportOptions";
import Breadcrumbs from "../common2/Breadcrumbs";
import { exportMultipleChartsToPdf } from "./ReoprtUtils";
import CommonAppFilter from "../navbar/sideNavbar/CommonAppFilter";
import { IState } from "../../redux/reducers/rootReducers";
import InputBox from "../common2/InputBox";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import AppLoader from "../common/AppLoader";
import LogoImg from "./../../assest/logo.png";
import { printColumns } from "./ReportCSV";
import {
  ColumnBarIcon,
  PieChartIcon,
  DonutBarIcon,
} from "../common/svgIcons/chartIcon";
import { url as AssetUrl } from "../../redux/actions/AssetsManagement/Assets";
import {
  formInfoData,
  getAllDAshboardData,
  multiApiCalls,
} from "../../redux/actions/TicketsManagement/TicketsManagement";
import { getTicketsUrl } from "../../services/dashboard/dashboard.service";
import { getAllContactsInfo } from "../../services/cmdb/Organization";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import getReportsAction, {
  getReportById,
  addEditReportAction,
} from "../../redux/actions/Reports/reports";
import { useNavigate, useParams } from "react-router-dom";
import { storeFiltersValue } from "../../redux/actions/TicketsManagement/GetTickets";
import CustomModalDialog from "../common/customDialog";
import PivotTableModal from "./PivotTableModal";
import moment from "moment";

const generateTimeRange = (FiltersVal: any) => {
  if (FiltersVal?.rangeVal) {
    return (
      <p
        style={{
          color: "black",
          textDecoration: "underline",
          marginTop: "10px",
        }}
      >
        {moment(FiltersVal?.rangeVal?.startDate).format("DD-dddd-YY")} -{" "}
        {moment(FiltersVal?.rangeVal?.endDate).format("DD-dddd-YY")}
      </p>
    );
  }
};

let timeout: any = null;
function ViewReport() {
  const param: any = useParams();
  const classes = CommonStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    id: 0,
    name: "",
    chartType: "",
    isActive: true,
    filter: "",
  });
  const [showAddNew, setShowAddNew] = useState(false);
  const [chartEditIndex, setChartEditIndex] = useState(-1);
  const [chartTitle, setChartTitle] = useState("");
  const [reportTitle, setReportTitle] = useState("");
  const [dataModal, setDataModal] = useState<any>({});
  const [ContactsInfo, setContactsInfo] = useState({} as any);
  const [isOpen, setIsOpen] = useState(false);
  const [chartsList, setChartsList] = useState<any>([]);
  const [imgList, setImgList] = useState<any>([]);
  const [allTickets, setallTickets] = useState([]);
  const [radioVal, setRadioVal] = useState("ticketType");
  const profileImg = useSelector(
    (state: IState) => state?.agentReducer?.saveProfile
  );
  const [state, setState] = useState<any>({
    //     "rows": ["Agent"],
    // "cols": ["Contacts"],
  });

  const AllInfo: any = useSelector(
    (state: IState) => state?.TicketsReducer?.alldataStructure
  );
  const AllReports = useSelector(
    (state: IState) => state?.ReportsReducer?.AllReports
  );
  const [loader, setloader] = useState(false);
  const AllTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.otherTickets
  );
  const FiltersVal: any = useSelector(
    (state: IState) => state?.filtersTicketManagementReducer
  );
  const [logoSrc, setLogoSrc] = useState(profileImg);

  useEffect(() => {
    if (param.id) {
      getReportById(param.id, getByIdCB);
    } else {
      setChartsList([]);
    }
    console.log("profileImg", profileImg);
  }, [param.id]);

  const getByIdCB = (resStatus: string, res: any) => {
    console.log("getbyID", JSON.parse(res?.filterValue));

    const tempCharts: any = JSON.parse(res.value);
    setChartsList(tempCharts?.values);
    setData({ ...res });
    setReportTitle(res?.name);
    dispatch(
      storeFiltersValue(
        JSON.parse(res?.filterValue)?.agents,
        "TCKT_AGENTS_VALUE"
      )
    );
    let temp: any = JSON.parse(res?.filterValue);

    // dispatch(
    //   storeFiltersValue(
    //     { node: temp.node, ...temp.rangeVal },
    //     "TCKT_RANGE_VALUE"
    //   )
    // );
  };
  const returnOptions = (item: any) => {
    return columnChartOptionsData(
      item.title,
      allTickets,
      item.value,
      dataModal?.[item.value]
    );
  };
  // useEffect(() => {
  //   const tmpObj = {
  //     ticketType: AllInfo?.TicketTypes,
  //     status: AllInfo?.status,
  //     ownerId: AllInfo?.Agents,
  //     groupId: AllInfo?.groups,
  //     contactId: AllInfo?.contacts,
  //     contactLoc: ContactsInfo?.locations,
  //     contactDiv: ContactsInfo?.divisions,
  //     contactOrg: ContactsInfo?.organisation,
  //   };
  //   console.log("tmpObj", AllInfo, tmpObj);
  //   setDataModal(tmpObj);
  // }, [AllInfo, ContactsInfo]);
  const stopLoader = () => {
    setloader(false);
  };
  const exportPDF = () => {
    setloader(true);
    setTimeout(() => {
      exportMultipleChartsToPdf(stopLoader);
    }, 100);
  };
  const fileChangeHandler = (e: any) => {
    console.log(e.target.value);
    var reader: any = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      console.log(reader.result);
      setLogoSrc(reader.result);
    };
    reader.onerror = function (error: any) {
      console.log("Error: ", error);
    };
  };
  const onChangeInputHandler = (e: any) => {
    console.log("e.target.value", e.target.value);
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleChangeRadio = (e: any) => {
    console.log("e.target.value", e.target.value);
    setRadioVal(e.target.value);
  };
  const PageButtons = (props: any) => {
    const { onClickHandler, title } = props;
    return (
      <Button className={classes.ReportBtns} onClick={onClickHandler}>
        {title}
      </Button>
    );
  };
  const getReportsCB = (resStatus: string, data: any) => {
    console.log("123data", resStatus, data);
  };
  // useEffect(() => {
  //   setloader(true);
  //   //dispatch(getReportsAction(getReportsCB))
  //   dispatch(getAllContactsInfo(contactsInfoDataCB));
  //   dispatch(getAllDAshboardData(getAllDAshboardDataCB));
  // }, []);
  const getAllDAshboardDataCB = (statusTypes: any, groups: any = []) => {
    console.log("getAllDAshboardDataCB");
  };
  const multiApiCB = (resStatus: string, res: any) => {
    console.log("multiApiCB", res);
    if (resStatus === "0") {
      console.log("ContactsInfo>>", AllTickets);
      const tempTickets: any = [];
      AllTickets.map((i: any) => {
        if (!i?.ticketLogs) return;
        let logs = i.ticketLogs;
        const tempObj: any = {};
        let contactInfo: any = {};
        contactInfo = AllInfo?.contactsById?.[`${logs["contactId"]}`]
          ? AllInfo?.contactsById?.[`${logs["contactId"]}`]
          : {};
        const {
          email,
          jobTitle,
          contactOrganisationId,
          divisionId,
          baseLocationId,
        } = contactInfo;
        tempObj["contactEmail"] = email ? email : "-";
        tempObj["contactJob"] = jobTitle ? jobTitle : "-";
        tempObj["contactOrg"] = contactOrganisationId;
        tempObj["contactDiv"] = divisionId;
        tempObj["contactLoc"] = baseLocationId;
        logs = { ...logs, ...tempObj };
        tempTickets.push({ ...i, ticketLogs: logs });
        console.log("tempObj", { ...i, ticketLogs: logs });
      });

      setallTickets(tempTickets);
      stopLoader();
    }
  };
  const contactsInfoDataCB = (resStatus: string, res: any) => {
    console.log("contactsInfoDataCB", resStatus, res);
    if (resStatus === "0") {
      setContactsInfo(res);
      setTimeout(() => {
        dispatch(multiApiCalls([getTicketsUrl], ["Tickets"], multiApiCB));
      }, 1000);
    }
  };
  const deleteReport = (index: number) => {
    console.log(chartsList[index]);
    const tempRecords = [...chartsList];
    tempRecords.splice(index, 1);
    setChartsList(tempRecords);
  };
  const editReport = (index: number) => {
    console.log("filter value", chartsList[index]);
    setIsOpen(true);
    const tempRec = chartsList[index];
    setChartEditIndex(index);
    setShowAddNew(true);
    // setRadioVal(tempRec.value);
    setChartTitle(tempRec.title);
    setState(chartsList[index].value);
    const tempObj = {
      title: chartTitle,
      value: {
        cols: state.cols,
        rows: state.rows,
        rendererName: state.rendererName,
        aggregatorName: state.aggregatorName,
        vals: state.vals,
      },
      type: "column",
      default: true,
    };
    const tempArr = [...chartsList];
    tempArr[chartEditIndex] = tempObj;
    setChartsList(tempArr);
  };
  const addRecord = () => {
    console.log("addRecord", chartsList, state);
    const tempObj = {
      title: chartTitle,
      value: {
        cols: state.cols,
        rows: state.rows,
        rendererName: state.rendererName,
        aggregatorName: state.aggregatorName,
        vals: state.vals,
      },
      type: "column",
      default: true,
    };
    if (chartEditIndex === -1) {
      setChartsList([...chartsList, tempObj]);
    } else {
      const tempArr = [...chartsList];
      tempArr[chartEditIndex] = tempObj;
      setChartsList(tempArr);
      setChartEditIndex(-1);
    }
    clearFields();
  };
  const clearFields = () => {
    setRadioVal("ticketType");
    setChartTitle("");
    setShowAddNew(false);
  };
  const saveCallback = (resStatus: string, response: any) => {
    if (resStatus !== "0") return;
    console.log("callback", resStatus, response);
    navigate("/All-Reports");
  };
  const saveReport = () => {
    if (data?.name?.length) {
      const tempReq = {
        ...data,
        value: JSON.stringify({ values: chartsList }),
        filterValue: JSON.stringify(FiltersVal),
      };
      dispatch(addEditReportAction(tempReq, saveCallback, param.id));
      console.log("chartsList", tempReq);
    }
  };
  const handleExportPDF = async () => {
    addRecord();
    setIsOpen(false);
  };
  return (
    <div>
      <AppLoader enabled={loader} />
      <Box>
        <Box className={`${classes.row} ${classes.justifyCenter} px-2`}>
          <div className="custom-chart">
            <input
              type="file"
              name=""
              id="reportLogo"
              className="d-none"
              onChange={fileChangeHandler}
            />
            <label htmlFor="reportLogo">
              <img
                src={logoSrc === "" ? LogoImg : LogoImg}
                alt=""
                style={{ height: "60px" }}
              />
            </label>

            {generateTimeRange(FiltersVal)}
            {chartsList?.map((item: any) => {
              return (
                <div className="hide-pivot-container" style={{ width: "100%" }}>
                  {item?.title && (
                    <Typography
                      variant="body2"
                      className="li-title"
                      style={{
                        color: "black",
                        marginTop: "15px",
                        fontSize: "20px",
                        textDecoration: "underline",
                      }}
                    >
                      {item?.title}
                    </Typography>
                  )}
                  <PivotTableModal
                    data={AllTickets}
                    onClick={handleExportPDF}
                    state={item.value}
                    setState={setState}
                  />
                </div>
              );
            })}
          </div>
        </Box>
        {/* <Chart chartOptions={lineChartOptions} /> 
                    <Chart chartOptions={lineChartOptions} /> */}
        {/* <Chart chartOptions={columnChartOptions} />
                    <Chart chartOptions={barChartOptions} /> */}
        {/* {chartsList.map((i:any)=>{
                        return(
                            <>
                                <Chart chartOptions={returnOptions(i)} />
                            </>
                        )
                    })} */}
      </Box>
    </div>
  );
}
export default ViewReport;
