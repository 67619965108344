function timeSpanToReadbleFormat(timeSpan: string,type?:string): string {
  if (!timeSpan) {
    return 'Error: Time span is empty';
  }

  const timeParts: string[] = timeSpan.split(':');
  let formattedTimeSpan: string = '';
  let days: string = '';

  // Check if there are days and append to formattedTimeSpan
  const daysIndex: number = timeParts[0].indexOf('.');
  if (daysIndex !== -1) {
    days = timeParts[0].substring(0, daysIndex);
    formattedTimeSpan += `${days} d`;
    if (parseInt(days) > 1) {
      formattedTimeSpan += '';
    }
    formattedTimeSpan += ' ';
  }

  // Append hours and minutes to formattedTimeSpan
  const hoursIndex: number = daysIndex !== -1 ? daysIndex + 1 : 0;
  const hours: string = timeParts[0].substring(hoursIndex) || '0';
  const mins: string = timeParts[1] || '0';
  if (parseInt(days) > 0 || parseInt(hours) > 0 || parseInt(mins) > 0) {
    if (parseInt(hours) > 0) {
      formattedTimeSpan += `${parseInt(hours)} ${parseInt(hours) === 1 ? 'h' : 'h'} `;
    }
    if (parseInt(mins) > 0) {
      if (parseInt(hours) > 0) {
        formattedTimeSpan += `${parseInt(mins)} ${parseInt(mins) === 1 ? 'm' : 'm'} `;
      } else {
        formattedTimeSpan += `${parseInt(mins)} ${parseInt(mins) === 1 ? 'm' : 'm'}`;
        if (parseInt(mins) < 60) {
          formattedTimeSpan += ' ';
        }
      }
    }
  } else {
    formattedTimeSpan = '0';
  }

  // Remove trailing space
  formattedTimeSpan = formattedTimeSpan.trim();

  return formattedTimeSpan;
  }
  export default  timeSpanToReadbleFormat


  