import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, Tooltip);

const footer = (tooltipItems: any) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem: any) {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};
export const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    tooltip: {
      usePointStyle: true,
      callbacks: {
        footer: footer,
      },
      filter: function (tooltipItem: any) {
       // console.log('tooltipItem.datasetIndex', tooltipItem.dataIndex,tooltipItem.dataset.data[tooltipItem.dataIndex]  )
        var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
        if (value === 0) {
            return true;
        } else {
            return true;
        }
    }
    },
    title: {
      display: false,
      text: "Chart.js B",
    },
    datalabels: {
      display: false,
      align: "center",
      anchor: "center",
      formatter: function (value: any, index: any, values: any) {
        // if (value > 0) {
          value = value.toString();
          value = value.split(/(?=(?:...)*$)/);
          value = value.join(",");
          return value;
        // } else {
        //   value = "";
        //   return value;
        // }
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  scales: {
    x: {
      stacked: true,
      
    },
    y: {
      stacked: true,
      ticks: {
        callback: (yValue: any) => {
          return Math.floor(yValue); // format to your liking
        },
      },
    }
  },
  
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
};

const HorizontalChart = ({
  id='myReportBar',
  label,
  dataSets,
  callback,
}: {
  label: any;
  dataSets: any;
  callback?: any;
  id?: any
}) => {
  const data = {
    labels: label,
    datasets: dataSets,
  };
  const onchange = () =>{
    console.log(callback)
    if (callback) callback()
  }
  React.useEffect(()=>{
    setTimeout(()=>{
      onchange()
    },100)
  },[label])
  return <Bar id={id} data={data} options={options} onChange={onchange}
  />;
};
export default HorizontalChart;
