import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import teamsIcon from "../../../assest/emojis/teams.svg";
import whatsappIcon from "../../../assest/emojis/whatsapp-icon.svg";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import MergeIcon from "@mui/icons-material/Merge";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import LinkIcon from "@mui/icons-material/Link";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Link } from "react-router-dom";
import { IState } from "../../../redux/reducers/rootReducers";
import { createStyles, makeStyles } from "@mui/styles";
import CustomChip from "../../common/chip/CustomChip";
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
export const TicketActions = (props: any) => {
  const { ticketData,parentId } = props;
  const {
    ticketTypeId,
    contactId,
    secondaryContactId,
    categoryId,
    priorityId,
    title,
    description,
    openDateTime,
    dueDateTime,
    firstResponseDueDateTime,
    nextActionDescription,
    groupId,
    statusId,
    agentId,
    tagList,
    setIsShow,
    isShow,
  } = ticketData?.ticketLogs;
  const rolesAccess = useSelector(
    (state: IState) => state?.LoginReducer?.roleAgentAccess?.ticketTypeAccess
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addCategoryPopup, setAddCategoryPopup] = useState<any>(false);
  const [type, setType] = useState<any>("");

  const handleMerge = useCallback((ticketData: any, type: string) => {
    dispatch({
      type: "LINK_MERGE_POPUP_INFO",
      payload: {ticketData,type,parentId},
    });
  }, [ticketData]);
  // const onEditClick = useCallback(() => {
  //     navigate(`/home/ticket/${val}`)
  // /})
  // console.log(ticketData)
  const onQuickUpdate = useCallback(() => {
    dispatch({
      type: "ADD_UPDATE_POPUP_INFO",
      payload: ticketData,
    });
  }, [ticketData]);
  const openWhatsAppMessenger = useCallback(() => {
    dispatch({
      type: "WHATSAPP_POPUP_INFO",
      payload: ticketData,
    });
    
  }, [ticketData])
  return (
    <>
      <Box className={`${classes.moreBoxOptions} ticket-moreOptionsBox`}>
        <Box className={`${classes.row} `}>
          {(rolesAccess?.isSuperAdmin || rolesAccess?.[ticketTypeId]?.Update) && (
            <IconButton
              className={classes.ticketCardiconButton}
              aria-label="Quick Update"
              onClick={onQuickUpdate}
            >
              <Tooltip title="Quick Update">
                <UpdateOutlinedIcon />
              </Tooltip>
            </IconButton>
          )}
          {(rolesAccess?.isSuperAdmin || rolesAccess?.[ticketTypeId]?.Timeline) && (
            <Link to={`/history/${ticketData?.id}`}>
              <IconButton
                className={classes.ticketCardiconButton}
                aria-label="TimeLine"
              >
                <Tooltip title="TimeLine">
                  <AccessAlarmOutlinedIcon />
                </Tooltip>
              </IconButton>
            </Link>
          )}

          {(rolesAccess?.isSuperAdmin || rolesAccess?.[ticketTypeId]?.View) && (
            <IconButton
              className={classes.ticketCardiconButton}
              aria-label="View"
              onClick={() => {
                //openView()
                navigate(`/ticketInfoView/${ticketData.id}`);
              }}
            >
              <Tooltip title="View">
                <RemoveRedEyeOutlinedIcon />
              </Tooltip>
            </IconButton>
          )}
          {(rolesAccess?.isSuperAdmin || rolesAccess?.[ticketTypeId]?.Edit) && (
            <Link to={`/home/ticket/${ticketData?.id}`}>
              <IconButton
                className={classes.ticketCardiconButton}
                aria-label="Add"
              >
                <Tooltip title="Edit">
                  <ModeEditOutlineOutlinedIcon />
                </Tooltip>
              </IconButton>
            </Link>
          )}
          {props.type !== "dontShowChildToggleIcon" &&
            ticketData?.ticketRelationshipList?.length > 0 && (
              <IconButton
                className={classes.ticketCardiconButton}
                aria-label="Child"
                onClick={() => {
                  props.setIsShow(!props.isShow);
                }}
              >
                <Tooltip title="Child">
                  {props.isShow ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </Tooltip>
              </IconButton>
            )}

          {ticketData?.ticketLogs?.contacts?.teamsId && (
            <IconButton
              className={classes.ticketCardiconButton}
              aria-label="Teams"
            >
              <Tooltip title="Teams">
                <a
                  href={`https://teams.microsoft.com/l/chat/0/0?users=${ticketData.ticketLogs.contactVal?.teamsId}`}
                  target="blank"
                >
                  <Avatar
                    sx={{ width: 14, height: 14, borderRadius:0 }}
                    alt={"teams"}
                    src={teamsIcon}
                  />
                </a>
              </Tooltip>
            </IconButton>
          )}
          {ticketData?.ticketLogs?.contacts?.whatsAppNumber && (
            <IconButton
              className={classes.ticketCardiconButton}
              aria-label="Whatsapp"
              onClick={openWhatsAppMessenger}
            >
              <Avatar
                sx={{ width: 14, height: 14, borderRadius:0 }}
                alt={"teams"}
                src={whatsappIcon}
              />
            </IconButton>
          )}
          {(rolesAccess?.isSuperAdmin ||
            rolesAccess?.[ticketTypeId]?.Merge ||
            rolesAccess?.[ticketTypeId]?.Link ||
            rolesAccess?.[ticketTypeId]?.Split) && (
            <Box className="more-box">
              <IconButton
                className={classes.ticketCardiconButton}
                aria-label="More"
              >
                <MoreVertOutlinedIcon />
              </IconButton>
              <Box className={"more-options"}>
                {(rolesAccess?.isSuperAdmin ||
                  rolesAccess?.[ticketTypeId]?.Merge) && ticketData?.ticketLogs?.ticketStatus?.name !=="Merge" &&
                  props.type!=="child" && (
                  <MenuItem
                    onClick={() => {
                      handleMerge(ticketData, "merge");
                    }}
                  >
                    <MergeIcon /> <span style={{ marginLeft: 10 }}>Merge</span>
                  </MenuItem>
                )}
                {(rolesAccess?.isSuperAdmin ||
                  rolesAccess?.[ticketTypeId]?.Merge) && ticketData?.ticketLogs?.ticketStatus?.name ==="Merge" &&
                  props.type!=="child" && (
                  <MenuItem
                    onClick={() => {
                      handleMerge(ticketData, "unMerge");
                    }}
                  >
                    <CallSplitIcon /> <span style={{ marginLeft: 10 }}>UnMerge</span>
                  </MenuItem>
                )}
                {( props.type!=="child" && rolesAccess?.isSuperAdmin ||
                  rolesAccess?.[ticketTypeId]?.Link) && (
                  <MenuItem
                    onClick={() => {
                      handleMerge(ticketData, "link");
                    }}
                  >
                    <LinkIcon /> <span style={{ marginLeft: 10 }}>Link</span>
                  </MenuItem>
                )}
                {props.type=="child"  &&
                <MenuItem
                onClick={() => {
                  handleMerge(ticketData, "unLink");
                }}
              >
                <LinkIcon /> <span style={{ marginLeft: 10 }}>Unlink</span>
              </MenuItem>
                }
                {(rolesAccess?.isSuperAdmin ||
                  rolesAccess?.[ticketTypeId]?.Split) && (
                  <MenuItem
                    onClick={() => {
                      var tagList = JSON.stringify(ticketData?.ticketTaskList);
                      // onMergeClick(ticketData, "link");
                      navigate(
                        `/home/ticket/new-ticket?primaryContact=${contactId}&ticketType=${ticketTypeId}&secondaryContact=${secondaryContactId}&category=${categoryId}&priority=${priorityId}&subject=${title}&desc=${description}&openDate=${openDateTime}&dueDate=${dueDateTime}&nextActionDate=${firstResponseDueDateTime}&nextActionDesc=${nextActionDescription}&group=${groupId}&status=${statusId}&owner=${agentId}tags=${tagList}`
                      );
                    }}
                  >
                    <ContentCopyOutlinedIcon />{" "}
                    <span style={{ marginLeft: 10 }}>Clone</span>
                  </MenuItem>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
const useStyles: any = makeStyles(() =>
  createStyles({
    row: {
      display: "flex",
    },
    ticketCardiconButton: {
      fontSize: "10px !important",
      padding: "0",
      marginLeft: "7px",
      "& svg": {
        height: ".6em",
        width: ".6em",
      },
    },
    moreBoxOptions: {
      "& > div  *" :{
        display : "block",
        lineHeight:1
      },
      "& .more-box": {
        position: "relative",
        "& .more-options": {
          display: "none",
          position: "absolute",
          background: "#fff",
          bottom: "15px",
          right: "6px",
          zIndex: "9",
          padding: "5px 10px",
          borderRadius: "7px",
          boxShadow: "0 0 4px 1px #ddd",
          "& li": {
            fontSize: "10px",
            borderBottom: "1px solid #ddd8",
            padding: "5px 5px",
            display:"flex",
            "& svg": {
              fontSize: "16px",
            },
            "&:last-child": {
              borderBottom: "1px solid #ddd0",
            },
          },
        },
        "&:hover": {
          "& .more-options": {
            display: "block",
          },
        },
      },
    },
  })
);
