import { useDispatch, useSelector } from "react-redux";
import { memo, useState } from "react";
import { storeFiltersValue } from "../../../../redux/actions/TicketsManagement/GetTickets";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { filterInitialState } from "../../../../redux/reducers/FiltersTicketManagement/FiltersTicketManagement";
import { IState } from "../../../../redux/reducers/rootReducers";
export const ResetFilters = memo(() => {
    const currAgent = useSelector(
        (state: IState) => state?.agentReducer?.loggedInAgent
    );
    console.log('filterInitialState',filterInitialState)
    const dispatch = useDispatch();
    const classes = useStyles();
    
    const clickHandler = () => {
        dispatch(storeFiltersValue({...filterInitialState,"agents":[currAgent.id]}, 'RESET_FILTER'));
    }
    return (
        <>
            <Typography onClick={clickHandler} className={classes.title} variant="body2">
                ResetFilter
            </Typography>
        </>
    )
})
const useStyles = makeStyles(() => ({
    title:{
        fontSize: "10px",
        cursor: "pointer"
    }
}))
export default ResetFilters;