// home page with graphs

import CustomCard from "../../components/modules/home/CustomCard";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LineChart from "../../components/charts/line/LineChart";
import DoughnutChart from "../../components/charts/dougnut/DoughnutChart";
import Masonry from "@mui/lab/Masonry";
import Box from "@mui/material/Box";
import PieChart from "../../components/charts/pie/PieChart";
import { Divider, Typography } from "@mui/material";
import HorizontalChart from "../../components/charts/horizontal-chart/HorizontalChart";
import VerticalChart from "../../components/charts/horizontal-chart/VerticalChart";
import React, { useEffect, useRef, useState } from "react";
import GaugeChart from "react-gauge-chart";
import ReactSpeedometer from "react-d3-speedometer";
import { Class, DriveEtaSharp } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import getTicketStatus, {
  getResponseOrResolutionBySla,
  getTicketTrends,
} from "../../redux/actions/Dashboard/insight";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import CustomDatePicker, {
  CustomDatePickerWithoutBorder,
} from "../../components/common/date-picker/CustomDatePicker";
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomDialog from "../../components/common/dialog/CustomDialog";
import StatusChart from "./StatusChart";
import TicketTrends from "./TicketTrends";
import CustomSelect from "../../components/common/customSelect/CustomSelect";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import { getPriority } from "../../redux/actions/SystemManagement/AddPriority";
import { getAgent } from "../../redux/actions/userManagement/agents";
import { getCategory } from "../../redux/actions/SystemManagement/AddCategory";
import CategoryTreeSelection from "../new-ticket/AddCategoryPopup";
import ChooseAllCategory from "../../components/common/ChooseAllCategory";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 469,
      sm: 699,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const getTicketStatusFun = async (ticketDashboardFilter: number) => {
  //  get ticket status
  let getAttchement: any = await getTicketStatus(ticketDashboardFilter);
  return getAttchement;
};
const getTicketTrendsFun = async () => {
  //  get ticket trends
  let result: any = await getTicketTrends();
  return result;
};
const getResponseOrResolutionBySlaFun = async () => {
  //  get ticket trends
  let result: any = await getResponseOrResolutionBySla();
  return result;
};
const labelsForTicketTrendsChart = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "October",
  "Septempber",
  "November",
  "December",
];
const labelsForTicketTrendsChartQuarterly = [
  "January",
  "February",
  "March",
  "April",
];
const labelsForFilterByUnResolved = ["Resolved", "Unresolved"];

const dataSetForFilterByUnResolved = [
  {
    label: "data set 1",
    data: [60, 40],
    backgroundColor: ["#46C2CB", "#FF7000"],
    borderColor: ["#46C2CB", "#FF7000"],
    borderWidth: 1,
  },
];
const dataSetForFilterByUnResolvedQuat = [
  {
    label: "data set 1",
    data: [10, 30],
    backgroundColor: ["#DC0000", "#FFB100"],
    borderColor: ["#DC0000", "#FFB100"],
    borderWidth: 1,
  },
];
const dataSetForFilterByUnResolvedToday = [
  {
    label: "data set 1",
    data: [3, 7],
    backgroundColor: ["#DC0000", "#FFB100"],
    borderColor: ["#DC0000", "#FFB100"],
    borderWidth: 1,
  },
];
const labelsForFilterByPriority = ["P1", "P2"];
const dataSetForFilterByPriority = [
  {
    label: "data set 1",
    data: [80, 20],
    backgroundColor: ["#DC0000", "#FFB100"],
    borderColor: ["#DC0000", "#FFB100"],
    borderWidth: 1,
  },
];
const dataSetForFilterByPriorityToday = [
  {
    label: "data set 1",
    data: [5, 3],
    backgroundColor: ["#DC0000", "#FFB100"],
    borderColor: ["#DC0000", "#FFB100"],
    borderWidth: 1,
  },
];
const dataSetForFilterByPriorityQuot = [
  {
    label: "data set 1",
    data: [10, 30],
    backgroundColor: ["#DC0000", "#FFB100"],
    borderColor: ["#DC0000", "#FFB100"],
    borderWidth: 1,
  },
];

const dataSetForTicketTrendsChart = [
  {
    label: "Incident",
    backgroundColor: "#5b62c3",
    borderColor: "#5b62c3",
    pointStyle: "circle",
    data: [0, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
    fill: true,
    pointRadius: 10,
    pointHoverRadius: 20,
    tension: 0,
  },
  {
    label: "Service request",
    backgroundColor: "#FFC93C",
    borderColor: "#FFC93C",
    pointStyle: "circle",
    data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 80, 90, 100],
    fill: true,
    pointRadius: 10,
    pointHoverRadius: 20,
    tension: 0,
  },
  {
    label: "Change",
    backgroundColor: "#A555EC",
    borderColor: "#A555EC",
    pointStyle: "circle",
    data: [0, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65],
    fill: true,
    pointRadius: 10,
    pointHoverRadius: 20,
    tension: 0,
  },
  {
    label: "Problem",
    backgroundColor: "#CB1C8D",
    borderColor: "#CB1C8D",
    pointStyle: "circle",
    data: [100, 50, 40, 30, 20, 10, 5, 0, 0, 0, 0, 0],
    fill: true,
    pointRadius: 10,
    pointHoverRadius: 20,
    tension: 0,
  },
];

const labelsForFilterByGroups = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

const dataSetForFilterByGroups = [
  {
    label: "Dataset 1",
    data: [50, 49],
    backgroundColor: "rgba(255, 99, 132, 0.5)",
  },
];

const useStyles: any = makeStyles(() =>
  createStyles({
    wrapper: {
      fontFamily: "'DM Sans', sans-serif !important",
      "& div, p, span": {
        fontFamily: "'DM Sans', sans-serif !important"
      },
      "& line-chart > *": {
        fontFamily: "'DM Sans', sans-serif !important"
      },
      padding: "10px 20px 100px 20px",
    },
    dashboardPulse:{
      display:"flex",
      gap:"10px",
      justifyContent:"center",
      textAlign:"center",
      "& p":{
        // minHeight:"30px"
        fontSize:"0.7rem !important"
      },
      "& .count":{
        fontSize:"2rem !important",
        lineHeight: "1"
      },
      "& .icon":{
        background: "#ccedec",
        lineHeight: "1",
        padding: "7px",
        borderRadius: "3px",
        paddingBottom: "4px",
        "& svg":{
          color: "rgb(0, 165, 162) !important",
          fontSize: "1.3rem",
          lineHeight: "1",
        }
      }
    },
    cardBox:{
      height: "100%",
      background: "#fff",
      borderLeft: "5px solid #c5d0f7",
      padding: "10px",
      "& p":{
        fontSize:"10px",
        "&.count":{
          fontSize:"20px",
        }
      }
    },
    card: {
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
      [theme.breakpoints.up("lg")]: {
        // width: "500px !important",
      },
    },
    card5: {
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
      [theme.breakpoints.up("lg")]: {
        width: "500px !important",
      },
    },
    card4: {
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
      [theme.breakpoints.up("lg")]: {
        width: "500px !important",
      },
    },
    cardForPriorityAndTickets: {
      [theme.breakpoints.down("lg")]: {
        width: "100% !important",
      },
      //  width:""
    },
  })
);
interface AgentList {
  [key: string]: number;
}

interface Line {
  count: number;
  agentList: AgentList;
}

interface Group {
  [lineName: string]: Line;
}

function transformGroup(group: Group): {[key: string]: number[]} {
  const result: {[key: string]: number[]} = {};

  const keys = Object.keys(group).reduce((allKeys, lineName) => {
    const line = group[lineName];
    return allKeys.concat(Object.keys(line.agentList));
  }, [] as string[]);

  for (const key of keys) {
    result[key] = Object.keys(group).map((lineName) => {
      const line = group[lineName];
      return line.agentList[key] || 0;
    });
  }

  return result;
}
const generateGroupData = (dataAll: any, agent: any) => {
  
  console.log("agent", agent);
  const colorPalatte: any = [
    "#809fff",
    "#b8e0d4",
    "#f8e16f",
    "#f4895f",
    "#95cf92",
    "#9656a2",
    "#E1C288",
    "#CCCCFF",
    "red",
    "green",
    "orange",
  ];

  let temp: any = [];

  
  const output = transformGroup(dataAll);


  Object.keys(output).map((item1: any, index: number) => {
    let a: any = {
      label: item1,//findFirstNameById(item1, agent),
      data: output[item1],
      backgroundColor: colorPalatte[index],
      stack: index,
      barThickness: 10,
    };
    temp.push(a);
  });
  console.log("generateGroupData--a", output);

  return temp;
};

const generateTicketTrendsData = (dataAll: any) => {
  const colorPalatte: any = [
    "#809fff",
    "#b8e0d4",
    "#f8e16f",
    "#f4895f",
    "#95cf92",
    "#9656a2",
    "#E1C288",
    "#CCCCFF",
    "red",
    "green",
    "orange",
  ];
  let temp: any = [];
  // Create an array of all ticket types
  // Create an array of all ticket types

  // Create an empty object to store the incident data
  const incidentData: any = {};

  const ticketTypeData: { [type: string]: number[] } = {};

  // Loop over the days in weeklyTicketTrends
  for (const [day, data] of Object.entries<any>(dataAll)) {
    // Loop over the ticket types on this day
    for (const [type, count] of Object.entries<number>(data.ticketTypes)) {
      // Add the count for this ticket type and day to the ticketTypeData object
      ticketTypeData[type] = ticketTypeData[type] || [];
      ticketTypeData[type].push(count);
    }
  }

  Object.keys(ticketTypeData).map((item1: any, index: number) => {
    let a: any = {
      label: item1,
      backgroundColor: colorPalatte[index],
      borderColor: colorPalatte[index],
      pointStyle: "circle",
      data: ticketTypeData[item1],
      fill: true,
      pointRadius: 10,
      pointHoverRadius: 20,
      tension: 0.6,
    };
    temp.push(a);
  });
  console.log("generateTicketTrendsData--a", temp);

  return temp;
};
function findFirstNameById(id: any, agentList: any): string {
  const user = agentList.find((user: any) => user.id == id);
  return user ? user.firstName : "";
}
const totalCount = (group: any) => {
  let totalCount = 0;
  for (const lineName in group) {
    if (group.hasOwnProperty(lineName)) {
      totalCount += group[lineName].count;
    }
  }

  console.log(totalCount);
  return totalCount;
};
const calculateSum = (data: any) => {
  const reducer = (accumulator: any, curr: any) => accumulator + curr;
  return data?.length > 0 ? data?.reduce(reducer) : "-";
};
const generateColor = (data: any, priorities: any) => {
  const colorPalatte: any = [
    "#809fff",
    "#b8e0d4",
    "#f8e16f",
    "#f4895f",
    "#95cf92",
    "#9656a2",
    "#E1C288",
    "#CCCCFF",
    "red",
    "green",
    "orange",
  ];
  let temp: any = [];
  data.map((item: number, index: number) => {
    temp.push(getColorByName(item, priorities));
  });
  console.log("data", temp);

  return temp;
};

function getColorByName(name: any, priorities: any): string | undefined {
  const priority = priorities.find((p: any) => p.name === name);
  return priority?.color;
}
const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const chartRef: any = useRef(null);
  const priority = useSelector(
    (state: IState) => state?.priorityReducer?.Data.data
  );
  const agent = useSelector((state: IState) => state.agentReducer?.Data?.data);
  
  // useEffect(() => {
  //   dispatch(getCategory());
    
  // }, []); // Empty dependency array to run the effect only once

  const [formDataForTicketTrends, setFormDataForTicketTrends] = useState<any>({
    timeFilterForTicketTrends: {
      label: "Weekly",
      id: 0,
      value: "weekly",
    },
    ticketTrendsLabel: [],
    ticketTrendsDataset: [],
  });
  const [formData, setFormData] = useState<any>({
    timeFilterSatus: { label: "All", id: 0, value: "All", initial: true },
    ticketStatus: {
      New: "-",
      NewtoMe: "-",
      InProgress: "-",
      Pending: "-",
      Resolved: "-",
      Closed: "-",
      OnHold: "-",
      ReOpen: "-",
    },
    //------------------------
    responseOrResolutionBySla: {
      resolutionslaCount: 0,
      responseslaCount: 0,
    },
    //------------------------
    timeFilterPriority: {
      label: "Today",
      id: 3,
      value: "Today",
      initial: true,
    },
    labelsForFilterByPriority: [],
    dataSetForFilterByPriority: [],
    //--------------------------------
    timeFilterUnresolved: {
      label: "Today",
      id: 3,
      value: "Today",
      initial: true,
    },
    labelsForFilterByUnResolved: labelsForFilterByUnResolved,
    dataSetForFilterByUnResolved: [],
    //--------------------------
    timeFilterGroups: { label: "Today", id: 3, value: "Today", initial: true },
    labelsForFilterByGroups: [],
    dataSetForFilterByGroups: [],
    //-------------------------
  });
  const [initialValue, setInitialValue] = useState<any>({});

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPriority());
    dispatch(getAgent());
    getTicketStatusFun(0).then((res: any) => {
      if (res.status == 200) {
        setInitialValue(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (formDataForTicketTrends?.timeFilterForTicketTrends?.id == 0) {
      getTicketTrendsFun().then((res: any) => {
        if (res.status == 200) {
          setFormDataForTicketTrends({
            ...formDataForTicketTrends,
            ticketTrendsLabel: Object.keys(res?.data?.weeklyTicketTrends),
            ticketTrendsDataset: generateTicketTrendsData(
              res?.data?.weeklyTicketTrends
            ),
          });
        }
      });
    } else {
      getTicketTrendsFun().then((res: any) => {
        if (res.status == 200) {
          setFormDataForTicketTrends({
            ...formDataForTicketTrends,
            ticketTrendsLabel: Object.keys(res?.data?.monthlyTicketTrends),
            ticketTrendsDataset: generateTicketTrendsData(
              res?.data?.monthlyTicketTrends
            ),
          });
        }
      });
    }
  }, [formDataForTicketTrends?.timeFilterForTicketTrends]);

  //---------------------------------------
  useEffect(() => {
    if (formData?.timeFilterSatus?.initial == false) {
      if (formData?.timeFilterSatus?.id == 1) {
        getTicketStatusFun(1).then((res: any) => {
          if (res.status == 200) {
            console.log("chart data", res.data);
            setFormData({
              ...formData,
              ticketStatus: res.data.ticketStatus,
              responseOrResolutionBySla: {
                resolutionslaCount: res.data.resolutionSlaCount,
                responseslaCount: res.data.responseSlaCount,
              },
              labelsForFilterByPriority: Object.keys(res.data.priority),
              dataSetForFilterByPriority: [
                {
                  label: "data set 1",
                  data: Object.values(res.data.priority),
                  backgroundColor: generateColor(
                    Object.keys(res.data.priority),
                    priority
                  ),
                  borderWidth: 0,
                },
              ],
              dataSetForFilterByUnResolved: [
                {
                  label: "data set 1",
                  data: [res.data.resolved, res.data.unResolved],
                  backgroundColor: ["#46C2CB", "#FF7000"],
                  borderColor: ["#46C2CB", "#FF7000"],
                  borderWidth: 1,
                },
              ],
              labelsForFilterByGroups: Object.keys(res.data.group),
              dataSetForFilterByGroups: generateGroupData(
                res.data.group,
                agent
              ),
            });
          }
        });
      } else {
        getTicketStatusFun(0).then((res: any) => {
          if (res.status == 200) {
            console.log("chart data", res.data);

            setFormData({
              ...formData,
              ticketStatus: res.data.ticketStatus,
              responseOrResolutionBySla: {
                resolutionslaCount: res.data.resolutionSlaCount,
                responseslaCount: res.data.responseSlaCount,
              },
              labelsForFilterByPriority: Object.keys(res.data.priority),
              dataSetForFilterByPriority: [
                {
                  label: "data set 1",
                  data: Object.values(res.data.priority),
                  backgroundColor: generateColor(
                    Object.keys(res.data.priority),
                    priority
                  ),
                  borderWidth: 0,
                },
              ],
              dataSetForFilterByUnResolved: [
                {
                  label: "data set 1",
                  data: [res.data.resolved, res.data.unResolved],
                  backgroundColor: ["#46C2CB", "#FF7000"],
                  borderColor: ["#46C2CB", "#FF7000"],
                  borderWidth: 1,
                },
              ],
              labelsForFilterByGroups: Object.keys(res.data.group),
              dataSetForFilterByGroups: generateGroupData(
                res.data.group,
                agent
              ),
            });
          }
        });
      }
    }
  }, [formData?.timeFilterSatus]);
  //-------

  useEffect(() => {
    if (priority?.length > 0 && agent?.length > 0) {
      console.log("initialValue", initialValue);
      if (Object.keys(initialValue)?.length >0) {
        setFormData({
          ...formData,
          ticketStatus: initialValue.ticketStatus,
          responseOrResolutionBySla: {
            resolutionslaCount: initialValue.resolutionSlaCount,
            responseslaCount: initialValue.responseSlaCount,
          },
          labelsForFilterByPriority: Object.keys(initialValue.priority),
          dataSetForFilterByPriority: [
            {
              label: "data set 1",
              data: Object.values(initialValue.priority),
              backgroundColor: generateColor(
                Object.keys(initialValue.priority),
                priority
              ),
              borderWidth: 0,
            },
          ],
          dataSetForFilterByUnResolved: [
            {
              label: "data set 1",
              data: [initialValue.resolved, initialValue.unResolved],
              backgroundColor: ["#46C2CB", "#FF7000"],
              borderColor: ["#46C2CB", "#FF7000"],
              borderWidth: 1,
            },
          ],
          labelsForFilterByGroups: Object.keys(initialValue.group),
          dataSetForFilterByGroups: generateGroupData(
            initialValue.group,
            agent
          ),
        });
      }
    }
  }, [priority, agent, initialValue]);

  const [showPopup,setShowPopup] = useState(false)
  return (
    <>
      {/* <button onClick={() => {
                dispatch({
                    type:"CATEGORY_POPUP_TICKET_TYPE_ID",
                    payload : 57
                })
      }}>open modal</button>
      <button onClick={() => {
        setShowPopup(true);
      }}>open all</button>
      <CategoryTreeSelection callback={()=>{}}/>
      {showPopup &&<>
        <ChooseAllCategory 
          closeHandler = {()=>{setShowPopup(false)}}
          callback= {(values)=>{
            console.log("ChooseAllCategory_callback",values)
            setShowPopup(false)
          }}
        />
        </>
      } */}
      <div className={classes.wrapper}>
        <StatusChart
          formData={formData}
          classes={classes}
          setFormData={setFormData}
        />
        <CustomCard
          prefixIcon={""}
          headerColor="white"
          height="auto"
          width={"100%"}
          header={
            <Typography
              style={{ fontSize: "16px", color: "#5b62c3", fontWeight: 900 }}
            >
              Resolution/Response time by SLA
            </Typography>
          }
          subHeader={<></>}
          body={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                textAlign: "center",
                flexWrap: "wrap",
                gap: 20,
              }}
            >
              <div>
                <Typography className={`${classes.themeColorTxt}`} style={{ fontSize: "16px",}}>
                  Response time by SLA
                </Typography>
                <ReactSpeedometer
                  width={250}
                  height={150}
                  value={parseInt(
                    formData.responseOrResolutionBySla.responseslaCount
                  )}
                  currentValueText={"${value}%"}
                  valueTextFontSize={"37px"}
                  paddingVertical={17}
                  minValue={0}
                  maxValue={100}
                  segments={5}
                  maxSegmentLabels={5}
                />
              </div>
              <div>
                <Typography className={`${classes.themeColorTxt}`} style={{ fontSize: "16px",}}>
                  Resolution time by SLA
                </Typography>
                <ReactSpeedometer
                  width={250}
                  height={150}
                  value={parseInt(
                    formData.responseOrResolutionBySla.resolutionslaCount
                  )}
                  currentValueText={"${value}%"}
                  valueTextFontSize={"37px"}
                  paddingVertical={17}
                  minValue={0}
                  maxValue={100}
                  segments={5}
                  maxSegmentLabels={5}
                />
              </div>
            </div>
          }
        />
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------- */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: "30px",
            marginBottom: "80px",
            marginTop: "40px",
          }}
        >

          <div
            style={{ width: "100%" }}
            className={classes.cardForPriorityAndTickets}
          >

            <CustomCard
              prefixIcon={
                <div
                  style={{
                    fontSize: "40px",
                    color: "#5b62c3",
                    fontWeight: 900,
                    alignItems: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontSize: "12px" }}>
                    Total tickets by Priority
                  </span>
                  <span style={{ fontSize: "50px" }}>
                    {calculateSum(
                      formData?.dataSetForFilterByPriority[0]?.data
                    )}
                  </span>
                </div>
              }
              headerColor="white"
              height="auto"
              width={"100%"}
              header={
                <div
                  style={{
                    fontSize: "16px",
                    color: "#5b62c3",
                    fontWeight: 900,
                  }}
                >
                  Tickets by Priority
                </div>
              }
              subHeader={<></>}
              body={
                calculateSum(formData?.dataSetForFilterByPriority[0]?.data) ==
                0 ? (
                  <div style={{ textAlign: "center" }}>
                    <span className="home-nodata" style={{ textAlign: "center" }}>
                      No data to show 🧐
                    </span>
                  </div>
                ) : (
                  <div style={{ height: "300px" }}>
                    <PieChart
                      labels={
                        formData.labelsForFilterByPriority
                      }
                      datasets={
                        formData.dataSetForFilterByPriority
                      }
                    />
                  </div>
                )
              }
            />
          </div>
          <div
            style={{ width: "100%" }}
            className={classes.cardForPriorityAndTickets}
          >
            <CustomCard
              className={classes.cardForPriorityAndTickets}
              prefixIcon={
                <div
                  style={{
                    fontSize: "40px",
                    color: "#5b62c3",
                    fontWeight: 900,
                    alignItems: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* <div
                    onClick={() => {
                      //save to png
                      const canvasSave: any = document.getElementById("chart-1");
                      canvasSave.toBlob(function (blob: any) {
                        saveAs(blob, "testing.png");
                      });
                    }}
                  >
                  
                  </div> */}
                  <span style={{ fontSize: "12px" }}>
                    Total resolved tickets(%)
                  </span>
                  <span style={{ fontSize: "50px" }}>
                    {formData?.dataSetForFilterByUnResolved[0]?.data
                      ? `${formData?.dataSetForFilterByUnResolved[0]?.data[0]}%`
                      : "-"}
                  </span>
                </div>
              }
              headerColor="white"
              height="auto"
              width={"100%"}
              header={
                <div
                  style={{
                    fontSize: "16px",
                    color: "#5b62c3",
                    fontWeight: 900,
                  }}
                >
                  Resolved/Unresolved Tickets
                </div>
              }
              subHeader={<></>}
              body={
                calculateSum(formData?.dataSetForFilterByUnResolved[0]?.data) ==
                0 ? (
                  <div style={{ textAlign: "center" }}>
                    <span className="home-nodata" style={{ textAlign: "center" }}>
                      No data to show 🧐
                    </span>
                  </div>
                ) : (
                  <div style={{ height: "300px" }}>
                    <DoughnutChart
                      id="chart-1"
                      ref={chartRef}
                      labels={
                        formData.labelsForFilterByUnResolved
                      }
                      datasets={
                        formData.dataSetForFilterByUnResolved
                      }
                    />
                  </div>
                )
              }
            />
          </div>
          <CustomCard
            prefixIcon={
              <div
                style={{
                  fontSize: "40px",
                  color: "#5b62c3",
                  fontWeight: 900,
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <span style={{ fontSize: "12px" }}>Total tickets by Group</span>
                <span style={{ fontSize: "50px" }}>
                  {calculateSum(formData?.dataSetForFilterByGroups[0]?.data)}
                  {totalCount(formData?.dataSetForFilterByGroups[0]?.data)}
                </span> */}
              </div>
            }
            headerColor="white"
            height="auto"
            width={"100%"}
            header={
              <div
                style={{ fontSize: "16px", color: "#5b62c3", fontWeight: 900 }}
              >
                Tickets by Groups
              </div>
            }
            subHeader={<></>}
            body={
              formData.labelsForFilterByGroups.lenfth > 0 ? (
                <div style={{ textAlign: "center" }}>
                  <span className="home-nodata" style={{ textAlign: "center" }}>
                    No data to show 🧐
                  </span>
                </div>
              ) : (
                <div style={{ position: "relative", overflowX: "scroll" }}>
                  <div
                    style={{
                      position: "relative",
                      // minWidth:"100%",
                      width: "1200px",
                      height: 250,
                    }}
                  >
                    <HorizontalChart
                      label={formData.labelsForFilterByGroups}
                      dataSets={formData.dataSetForFilterByGroups}
                    />
                  </div>
                </div>
              )
            }
          />

        </div>
        <Divider sx={{ marginTop: "10px", marginBottom: "20px" }}></Divider>
        <TicketTrends
          formData={formDataForTicketTrends}
          classes={classes}
          setFormData={setFormDataForTicketTrends}
        />
      </div>
    </>
  );
};

export default Home;
