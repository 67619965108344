import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import parse from 'html-react-parser';
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { iFormData } from "../../pages/new-ticket/LatestTicket/Type";
import { addFeedback } from "../../redux/actions/Survey/feedback";
import { getSurveyByCode } from "../../redux/actions/Survey/survey";
import { WebFormTextArea } from "../webForms/FieldTypes/TextArea";
import SelectWebformField from "../webForms/SelectWebformField";
import { iWebFormField } from "../webForms/schema";
const summaryconfigs: iWebFormField = {
    fieldPlaceholder: "Please enter your issue summary",
    fieldDescription: "",
    fieldLabel: "Summary",
    isMandatory: true,
    id: "webFormDescription"
}
export default function FeedbackView() {
    const param = useParams()
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const [surevyVal, setSurevyVal] = useState<any>(null)
    const [errValues, setErrValues] = useState<any>({});
    const [showForm, setShowForm] = useState<boolean>(true);
    const [tenantCode,setTenantCode]=useState<any>("")
    const [tokenCode,setTokencode]=useState<any>("")
    useEffect(() => {
        
        if (param?.id && tenantCode) {
            dispatch(getSurveyByCode(param.id, getSurveyByIdCB,tenantCode))
        }
        // dispatch(getSurveyById(param.id, getSurveyByIdCB))
    }, [param?.id,tenantCode])
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search).get('Tenant-Code');
        const queryParamToken= new URLSearchParams(location.search).get('token');
        
        setTenantCode(queryParams)
        setTokencode(queryParamToken)
    }, [])
    const getSurveyByIdCB = (resStatus: string, res: any) => {
        if (resStatus === "0") {
            setSurevyVal(res)
        }
    }
    const resetForm = () => {
        setShowForm(false);
        setTimeout(() => {
            setShowForm(true);
        }, 500)
    }
    console.log("0", surevyVal);
    const webFormFields = useMemo(() => <>
        {surevyVal?.webform?.webformFieldList?.map &&
            surevyVal?.webform?.webformFieldList?.map((i: any) => {
                console.log('errValues__errValues', errValues)
                const { fieldLabel, fieldType, fieldPlaceholder, fieldId } = i;
                i = {
                    ...i,
                    id: fieldId,
                    content: fieldType,
                    placeholder: fieldPlaceholder,
                    label: fieldLabel,
                    type: "text",
                }
                return (
                    <>
                        <SelectWebformField block={i} />
                        {errValues?.[fieldId] && <Typography variant="caption" className="txt-danger">{errValues?.[fieldId]}</Typography>}
                    </>
                )
            })
        }
    </>, [surevyVal?.webform?.webformFieldList?.map, errValues])
    const validateWebForm = (enteredVal: any = {}, fields = []) => {
        let errObj: any = {
            hasError: false,
        };
        if (enteredVal["webFormDescription"].trim().length === 0) {
            errObj["webFormDescription"] = "Please fill this field";
            errObj.hasError = true;
        }
        fields.map((i: any) => {
            // errObj[i.fieldId] = 
            if (!i.isMandatory) return;
            let fieldVal = enteredVal[i.fieldId];
            if (!fieldVal || !fieldVal?.trim || enteredVal[i.fieldId].replaceAll("pleaseSelect", "").trim().length === 0) {
                errObj[i.fieldId] = "Please fill this field";
                errObj.hasError = true;
            }
        });
        console.log("validateWebForm", enteredVal, fields, errObj)
        return errObj;
    }
    interface StringObject {
        [key: string]: string;
    }
    const mergeCheckBoxValues = (obj: StringObject) => {
        try {
            const tempObj: StringObject = {};
            Object.keys(obj).map((i) => {
                if (i.indexOf('checkBox_') === 0) {
                    let key = i.split('_')[1];
                    tempObj[key] = (tempObj[key] ? tempObj[key] + ',' : "") + obj[i]
                } else {
                    tempObj[i] = obj[i]
                }
            })
            return tempObj
        } catch (error) {
            console.log('mergeCheckBoxValuesERROR', error);
            return obj;
        }
    }
    const onFormSubmit = (event: any) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        let data: iFormData = {};
        formData.forEach((value, key) => {
            data[key] = value.toString();
        });
        data = mergeCheckBoxValues(data);

        const validationVal: any = validateWebForm(data, surevyVal?.webform?.webformFieldList);
        setErrValues(validationVal)
        console.log("onFormSubmit",data,surevyVal?.webform?.webformFieldList);
        let request:any = {
            "FeedbackValue": `${JSON.stringify(data)}`
        }
        if(tokenCode){
            dispatch(addFeedback(request,addFeedbackCB,tokenCode,tenantCode))
        }
    }
    const addFeedbackCB = (resStatus: string, res: any) => {
        if (resStatus === "0") {
            navigate("/")
        }
    }
    return (
        <>
            <Box className="pt-1">
                <Grid container spacing={1}>
                    <Grid item md={2} xs={12}>

                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Box className="white-bg article-viewer-box p-2">
                            {surevyVal && <>
                                <Typography variant="h4">
                                    {surevyVal?.name}
                                </Typography>
                                {parse(`${surevyVal?.body || ""}`)}
                            </>}
                            <hr />
                            <form onSubmit={onFormSubmit} >
                                <WebFormTextArea fieldConfig={summaryconfigs} value={null} editable={true} />
                                {/* {surevyVal && surevyVal?.webform && webFormFields} */}
                                {errValues?.["webFormDescription"] && <Typography variant="caption" className="txt-danger">{errValues["webFormDescription"]}</Typography>}
                                {surevyVal && surevyVal?.webform && showForm && webFormFields}
                                <Box className="pt-1 text-center">
                                    <Button type="submit" variant="contained">
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Grid>

                    <Grid item md={2} xs={12}>

                    </Grid>
                </Grid>
        </Box >
        </>
    )
}