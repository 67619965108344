import ReactSpeedometer from "react-d3-speedometer"
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomCard from "../../components/modules/home/CustomCard";
import LineChart from "../../components/charts/line/LineChart";
import CustomSelect from "../../components/common/customSelect/CustomSelect";



  

const TicketTrends =({formData, classes, setFormData}:{formData: any, classes: any, setFormData: any})=>{
    return <>
    <div className="card-months"
          style={{
            width: "100%",
            padding: "10px 10px 10px 10px",
            backgroundColor: "white",
            borderRadius: "10px",
            
          }}
        >
          <div
            style={{
              overflowX: "auto",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
              gap: 10,
            }}
          >

<CustomSelect
            isOpen={false}
            placeholder="Select a View"
            width={"200px"}
            name="primaryContact"
            isColor={false}
            isDisabled={false}
            isError={false}
            defaultValue={formData.timeFilterForTicketTrends}
            options={[
              {
                label: "Weekly",
                id: 0,
                value:"weekly"
              },
              {
                label: "Monthly",
                id: 1,
                value:"monthly"
              },
            ]}
            handleChange={(e: any) => {
                    setFormData({...formData, timeFilterForTicketTrends: e,})
            
                
            }}
            isMulti={false}
          />
            {/* <div
              style={{
                border: "1px solid grey",
                padding: "10px",
                borderRadius: "10px",
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                width: "auto",
                flexShrink: 0,
                height: "50px",
              }}
            >
              <FilterListIcon sx={{ color: "rgb(91, 98, 195)" }} />
              <div
                style={{
                  color: "rgb(91, 98, 195)",
                  fontFamily: "'DM Sans', sans-serif !important"
                }}
              >
                Time range
              </div>
            </div> */}
           
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "20px",
            marginTop: "20px",
            width: "auto",
          }}
        >
          <CustomCard
            prefixIcon={
              <div
                style={{
                  fontSize: "40px",
                  color: "#5b62c3",
                  fontWeight: 900,
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <span style={{ fontSize: "12px" }}>Total Opened tickets</span>
                <span style={{ fontSize: "50px" }}>
                  {}
                </span> */}
              </div>
            }
            headerColor="white"
            height="100%"
            width={"100%"}
            header={
              <>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#5b62c3",
                    fontFamily: "'DM Sans', sans-serif !important",
                    fontWeight: 900,
                  }}
                >
                  Ticket Trends
                </div>
              </>
            }
            subHeader={
              <></>
              // <div
              //   style={{
              //     fontSize: "12px",
              //     color: "grey",
              //     display: "flex",
              //     flexDirection: "row",
              //     alignItems: "center",
              //     gap: 10,
              //   }}
              // >
              //   From
              //   <div>
              //     <CustomDatePickerWithoutBorder
              //       isDisabled={false}
              //       onHandleChange={(e: any) => {
              //         setFormData({ ...formData, ticketTendsStartTime: e });
              //       }}
              //       value={formData.ticketTendsStartTime}
              //       isError={false}
              //       helperText={""}
              //       name={"startTime"}
              //     />
              //   </div>{" "}
              //   to{" "}
              //   <div>
              //     <CustomDatePickerWithoutBorder
              //       isDisabled={false}
              //       onHandleChange={(e: any) => {
              //         console.log("endTime", e);

              //         getTicketTrendsFun(
              //           new Date(formData.ticketTendsStartTime).toISOString(),
              //           new Date(formData.ticketTrendsEndTime).toISOString()
              //         ).then((res: any) => {
              //           if (res.status == 200) {
              //             let days: any = [];
              //             console.log("getTicketTrendsFun", res.data);

              //             res.data.filter((ele: any) => {
              //               console.log("getTicketTrendsFun", ele);
              //               days.push(
              //                 new Date(
              //                   ele.ticketLogs.openDateTime
              //                 ).toDateString()
              //               );
              //             });

              //             setFormData({
              //               ...formData,
              //               ticketTrendsLabel: days,
              //               ticketTrendsLabelticketTrendsEndTime: e,
              //             });
              //           }
              //         });
              //       }}
              //       value={formData.ticketTrendsEndTime}
              //       isError={false}
              //       helperText={""}
              //       name={"endTime"}
              //     />
              //   </div>
              // </div>
            }
            body={
              <div style={{ position: "relative", overflowX: "scroll" }}>
              <div
                style={{
                  position: "relative",
                  // minWidth:"100%",
                  width: "1200px",
                  height: 250,
                }}
              >
                <LineChart
                  datasets={formData?.ticketTrendsDataset}
                  labels={formData?.ticketTrendsLabel}
                />
              </div>
              </div>
            }
          />
          
          {/* <CustomCard
            prefixIcon={""}
            headerColor="white"
            height="auto"
            width={"100%"}
            header={
              <div style={{ fontSize: "16px", color: "black" }}>
                Response time by SLA
              </div>
            }
            subHeader={
              <div style={{ fontSize: "12px", color: "black" }}>
                Daywise- Calls
              </div>
            }
            body={
              <div>
                <ReactSpeedometer
                  width={250}
                  height={150}
                  value={parseInt(currentPercent)}
                  currentValueText={"${value}%"}
                  valueTextFontSize={"37px"}
                  paddingVertical={17}
                  minValue={0}
                  maxValue={100}
                  segments={5}
                  maxSegmentLabels={5}
                />
              </div>
            }
          /> */}
        </div>
    </>
}
export default TicketTrends