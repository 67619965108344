import React, { ReactChild, ReactChildren, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";

interface ITheme {
  children: ReactChild | ReactChildren;
}

const Theme: React.FC<ITheme> = (props: ITheme) => {
  const toggleDarkTheme = useSelector((state: IState) => state.theme.theme);

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: '#2F80ED',
      },
      background: {
        default: "#F0F3FE",
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const { children } = props;
  return (
    <ThemeProvider theme={toggleDarkTheme ? darkTheme : lightTheme}>
      <>{children}</>
    </ThemeProvider>
  );
};

export default Theme;
