import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getPriority } from '../../../redux/actions/SystemManagement/AddPriority';
import { getRole } from '../../../redux/actions/userManagement/role';
import { IState } from '../../../redux/reducers/rootReducers';
import "./Ranking.css";
import RankingContent from './RankingContent';
import Breadcrumbs from "./../../common2/Breadcrumbs";
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import { handleTime } from '../../helpers/helperFunctions';
import getSystemType from '../../../redux/actions/SystemTypes/SystemTypes';

const Ranking = (props:any) => {
  const {systemTypes={}, rolesList=[],priorityList=[]} = props;
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(true)
  useEffect(()=>{
    dispatch(getRole());
    dispatch(getPriority());
    if(!systemTypes?.Priorities){
      dispatch(getSystemType())
    }
  },[])
  useEffect(()=>{
    handleTime.cancel()
    handleTime.setup(()=>{
      console.log("RankingVal",systemTypes,rolesList,priorityList);
    });
    if(loading && systemTypes?.Priorities && systemTypes?.TicketStatus && rolesList.length >0 && priorityList.length>0){
      setLoading(false)
    }
  },[systemTypes,rolesList,priorityList])
  return (
    <>
    <Container className="ml-0">
      <Box className="pb-1 Ranking">
        <Breadcrumbs
          data={[
              {
                  title: 'User Management ',
                  path: '/UserManagement'
              },
              {
                  title: 'Ranking',
                  path: '/Ranking'
              }
          ]}
        />
      </Box>
      {loading ? <> Loading...! </> : <RankingContent />}
      </Container>
    </>
  )
}



const mapStateToProps = (state:IState) => {
  return ({
    systemTypes: state?.systemTypeReducer?.systemTpesByName,
    rolesList: state?.roleReducer?.Data?.data,
    priorityList: state?.priorityReducer?.Data?.data,
  })
};

export default connect(mapStateToProps)(Ranking);