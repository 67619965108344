import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Box, Button, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import deleteTop from "../../assest/icons/deleteTop.svg";
import addIcon from "../../assest/icons/plus.svg";
import closeIcon from "../../assest/icons/close.svg";
import { Checkbox, IconButton, Tooltip } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import intl from "react-intl-universal";
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import deleteIcon from "../../assest/icons/delete.svg";
import restoreIcon from "../../assest/icons/restore.svg";
import CommonStyles from "../common/CommonStyles";
import { green } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();
const useStyles = makeStyles(() =>
  createStyles({
    Box: {
      marginTop: "0px",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
      // marginLeft: "-15px",
      // marginRight: "-15px",
      borderBottom: "1px solid #ddd",
      "@media screen and (max-width : 980px)": {
        flexWrap: "wrap",
        // background: mode.solids,
        marginLeft: "-10px",
        marginRight: "-10px",
        ">div": {
          flexWrap: "wrap",
        },
      },
    },
    padLeft: {
      paddingLeft: "30px",
    },
    searchBox: {
      width: "200px",
    },
    input1: {
      height: 6,
      fontSize: "13px",
      fontWeight: 400,
    },
    searchIcon: {
      fontSize: "16px",
      marginLeft: "-5px",
    },
    btn: {
      "&.hover": {
        backgroundColor: "red",
      },
      marginLeft: "1rem",
      fontSize: "10px",
      textTransform: "none",
      "@media screen and (max-width : 980px)": {
        marginLeft: "5px",
        fontSize: "9px !important",
        textTransform: "uppercase",
        color: "#000000",
        "& img": {
          height: "14px",
        },
      },
    },
    activebtn: {
      border: "1px solid blue",
      marginLeft: "1rem",
      fontSize: "10px",
      textTransform: "none",
      "@media screen and (max-width : 980px)": {
        marginLeft: "5px",
        fontSize: "9px !important",
        textTransform: "uppercase",
        color: "#000000",
        "& img": {
          height: "14px",
        },
      },
    },
    title: {
      fontSize: 12,
      background: "transparent",
      border: "none",
      outline: "none",
      padding: 0,
      cursor: "none",
    },
    visibleCondtion: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      paddingLeft: "10px",
    },
    unVisibleCondtion: {
      display: "none",
    },
    backIcon: {
      marginLeft: "10px",
      marginRight: "10px",
    },
    TopFilter: {
      position: "sticky",
      top: "81px",
      // background: mode.solids,
      zIndex: 999,
      paddingTop: "10px",
      "& button": {
        // color: mode.text
      },
      "@media screen and (max-width : 980px)": {
        position: "static",
        marginLeft: "-10px",
      },
    },
    actBtn: {
      minWidth: "auto",
      "& img ": {
        marginLeft: "10px",
        height: "20px",
        cursor: "pointer",
        "@media screen and (max-width : 980px)": {
          height: "13px",
        },
      },
    },
  })
);

function TopFilter({
  SearchHandler,
  value,
  path,
  Data,
  hideActiveBtns,
  filterHandler,
  checkBoxHandler,
  checked,
  filterItems,
  updateAllHandler,
  bulkDeleteHandler,
  addHandler,
  titleState,
  temp,
  hideSelectAllOption,
  pathname,
  placeHolder,
  subNavTitle,
  redirectTo,
  hideToggleActive,
  hideToggleInActive,
  hideAddBtn = false,
  inActiveLabel = "In-Active",
  activeLabel = "Active"

}: any) {
  const classes = useStyles();
  const cStyles = CommonStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [activate, setActivate] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addDataHandler = () => {
    //setActivate(!activate)
    addHandler();
  };

  const filterItemsHandler = (data: any) => {
    if (data !== true) {
      setActivate(true);
    } else {
      setActivate(false);
    }
    console.log("filterItems(data);");
    filterItems(data);
  };

  useEffect(() => {
    filterItemsHandler(true);
  }, [Data]);

  return (
    <div className={`${classes.TopFilter} ${cStyles.themeBgColor} common-top-filter`}>
      {/* <Box className={classes.padLeft}>
        <Breadcrumbs />
      </Box> */}
      <Box className={`${classes.Box} contacts-first-filter`}>
        <Link to={redirectTo ? redirectTo : "/adminPanel"}>
          <Tooltip title="Back">
            <IconButton>
              <ArrowLeftIcon className="backIcon" />
            </IconButton>
          </Tooltip>
        </Link>
        {subNavTitle && (
          <Typography variant="h6" gutterBottom component="div">
            {subNavTitle}
          </Typography>
        )}
        {!SearchHandler ? (
          ""
        ) : (
          <TextField
            className={`${classes.searchBox} searchbox`}
            type="search"
            placeholder={placeHolder}
            variant="outlined"
            size="small"
            value={value}
            onChange={(e: any) => SearchHandler(e)}
            InputProps={{
              classes: { input: classes.input1 },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {path === undefined ? (
          // <Button size="small" className={classes.btn} onClick={addHandler}>
          //   <img src={addIcon} alt="add_icon" style={{ marginRight: "8px" }} />
          //   Add New
          // </Button>
          ""
        ) : (
          <Box
            className={`${
              path ? classes.visibleCondtion : classes.unVisibleCondtion
            } visibleCondition`}
          >
            {!hideAddBtn &&
            <>
            {pathname === "NA" || pathname === "/Ticket-Status" || pathname === "/Ticket-Types"|| pathname === "/Ticket-Type"  ||pathname === "/AssetStatus" ||pathname ==="/Location" ||pathname === "/Organization" ||pathname === "/Division" ||pathname === "/Department"||pathname === "/LocationType"||pathname === "/OrganizationType"? (
              <Button
                size="small"
                className={`${classes.btn} common-btn`}
                onClick={addDataHandler}
              >
                <img
                  src={addIcon}
                  alt="add_icon"
                  style={{ marginRight: "8px" }}
                />
                Add New
              </Button>
            ) : (
              <Link to={path} style={{ textDecoration: "none" }}>
                <Button size="small" className={`${classes.btn} common-btn`}>
                  <img
                    src={addIcon}
                    alt="add_icon"
                    style={{ marginRight: "8px" }}
                  />
                  Add New
                </Button>
              </Link>
            )}
            </>
            }
            {!hideSelectAllOption ? (
              <Typography className={`${classes.title} ${classes.btn} common-btn`}>
                <Checkbox
                checked={checked}
                  onChange={(e: any) => checkBoxHandler(e)}
                  style={{ marginRight: "6px" }}
                  disabled={temp?.length === 0 ? true : false}
                />
                Select All
              </Typography>
            ) : (
              <></>
            )}
            {checked && !hideSelectAllOption && (
              <Box>
                {/* <Tooltip title="delete all">
                <img
                  src={deleteTop}
                  alt="delete_icon"
                  style={{
                    marginLeft: "10px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                  onClick={(e: any) => bulkDeleteHandler(Data.id)}
                />
              </Tooltip> */}
                <Tooltip
                  title={
                    titleState
                      ? `${intl.get(activeLabel)}`
                      : `${intl.get(inActiveLabel)}`
                  }
                >
                  <Button
                    className={`${classes.btn}`}
                    disabled={temp?.length === 0 ? true : false}
                    onClick={(e: any) => updateAllHandler(Data, Data.id)}
                  >
                    {titleState !== true ? (
                      <img src={deleteIcon} alt="edit_icon" />
                    ) : (
                      <img src={restoreIcon }  />
                    )}
                  </Button>
                </Tooltip>
              </Box>
            )}
            {!hideActiveBtns ? (
              <>
              {/* {!hideToggleActive && */}
                <Button
                  size="small"
                  className={`cursor-pointer ${activate ? classes.activebtn : classes.btn} common-btn`}
                  onClick={(e) => {
                    filterItemsHandler(false);
                    //setActivate(true)
                  }}
                >
                  Show {inActiveLabel}
                </Button>
                {/* }
              {!hideToggleInActive && */}
                <Button
                  size="small"
                  className={` 
                  cursor-pointer
                  ${
                    activate !== true ? classes.activebtn : classes.btn
                  } common-btn`}
                  onClick={(e) => {
                    filterItemsHandler(true);
                    // setActivate(false)
                  }}
                >
                  Show {activeLabel}
                </Button>
              </>
            ) : (
              <></>
            )}
          </Box>
        )}
      </Box>
      {/* <hr
        style={{
          marginTop: "0.5rem",
          marginBottom: "1rem",
          border: 0,
          borderTop: "1px solid rgba(0,0,0,.1)",
        }}
      /> */}
    </div>
  );
}

export default TopFilter;
