import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutChart = ({
  labels,
  datasets,
  ref,
  id,
  showLegend = true,
  showLabels = true, 
}: {
  labels: any;
  datasets: any;
  ref?: any;
  id?: any;
  showLegend?: boolean;
  showLabels?: boolean; 
}) => {
  const options: any = {
    maintainAspectRatio: false,
    cutoutPercentage: 90,
    plugins: {
      legend: {
        display: showLegend,
        position: 'top' as const,
        labels: {
          usePointStyle: false,
          filter: (legendItem: any, data: any) => data.datasets[0].data[legendItem.index] !== 0,
          font: {
            size: 5, // Adjust the font size here
          },
          padding: 3, // Adjust the padding
        },
      },
      datalabels: {
        display: true, // Always show values
        labels: {
          name: {
            align: 'top',
            color: "black",
            font: { size: 9 },
            formatter: (value: any, ctx: any) => {
              return showLabels && ctx.chart.data.datasets[0].data[ctx.dataIndex] > 0
                ? ctx.chart.data.labels[ctx.dataIndex]
                : '';
            },
          },
          value: {
            color: "black",
            font: { size: showLabels ? 7 : 9 },
            formatter: (value: any, ctx: any) => {
              return showLabels ? `${ctx.chart.data.datasets[0].data[ctx.dataIndex]}%` :`${ctx.chart.data.datasets[0].data[ctx.dataIndex]}`; // Always show values
            },
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return (
    <Doughnut
      ref={ref}
      data={data}
      options={options}
      id={id}
    />
  );
};

export default DoughnutChart;
