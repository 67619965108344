import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useEffect, useState } from "react";
import { storeFiltersValue } from "../../../../redux/actions/TicketsManagement/GetTickets";
import { IState } from "../../../../redux/reducers/rootReducers";
import CustomTheme from "../../../common/CustomTheme";
import CommonPopover from "../../../common/popover/CommonPopover";
import CommonStyles from "../../../common/CommonStyles";
import { DateRangePicker } from "react-date-range";
import { addDays, subMonths, subYears } from "date-fns";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { categorizeDateRange } from "../../../helpers/helperFunctions";
const CalendarRangeOption = memo(() => {
  const dispatch = useDispatch();
  const cstyles = CommonStyles();
  const classes = useStyles();
  const FiltersVal: any = useSelector(
    (state: IState) => state?.filtersTicketManagementReducer?.rangeVal
  );
  const [DaysList, setDaysList] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dateRangeFilterVal, setdateRangeFilterVal] = useState("OpenDateTime");

  const [rangeState, setRangeState] = useState<any>([
    {
      startDate: subYears(new Date(), 1),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  console.log("FiltersValFiltersVal", rangeState);

  useEffect(() => {
    if (FiltersVal) {
      setRangeState([
        {
          startDate: new Date(FiltersVal.startDate),
          endDate: new Date(FiltersVal.endDate),
          key: "selection",
          node: FiltersVal.node,
        },
      ]);
      setdateRangeFilterVal(FiltersVal.node);
    } else {
      setRangeState([
        {
          startDate: subYears(new Date(), 1),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ]);
    }
  }, [FiltersVal]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnChange = (ranges: any) => {
    const { selection } = ranges;
    setRangeState([selection]);
  };
  const applyDate = () => {
    handleCloseRangePicker();
    const { startDate, endDate } = rangeState[0];
    // let payload = {
    //   startDate: rangeState[0].startDate.setHours(0, 0, 0, 0),
    //   endDate: rangeState[0].endDate.setHours(23, 59, 59, 999),
    //   ...rangeState[0]
    // }
    console.log(
      "sssssssss",
      dateRangeFilterVal,rangeState[0]
    );

    dispatch(
      storeFiltersValue(
        {...rangeState[0], node: dateRangeFilterVal },
        "TCKT_RANGE_VALUE"
      )
    );
  };
  const filterDateTypeChange = (e: any) => {
    console.log("filterDateTypeChange", e.target.value);
    setdateRangeFilterVal(e.target.value);
  };
  const handleCloseRangePicker = () => {
    setAnchorEl(null);
  };
  const selectDateRange = (e: any) => {
    setAnchorEl(e.target);
  };
  return (
    <Box>
      <div
        className={[
          classes.title,
          cstyles.themeColorTxt,
          classes.titleDate,
        ].join(" ")}
      >
        <span onClick={(e: any) => selectDateRange(e)}>
          From <b>{moment(rangeState?.[0]?.["startDate"])?.format("L")} </b> (
          {DaysList[rangeState?.[0]?.["startDate"]?.getDay()]}) to{" "}
          <b> {moment(rangeState?.[0]?.["endDate"])?.format("L")}</b> (
          {DaysList[rangeState?.[0]?.["endDate"]?.getDay()]}){"("}
          {categorizeDateRange(
            rangeState?.[0]?.["startDate"],
            rangeState?.[0]?.["endDate"]
          )}
          {")"}
        </span>

        <DateRangeOutlinedIcon
          onClick={(e: any) => selectDateRange(e)}
          className={`${classes.icon}`}
        />
      </div>
      <CommonPopover
        id="filterPopoverDateRange"
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        bodyContent={
          <>
            <Box>
              <Box className={classes.dateInnerContainer}>
                <DateRangePicker
                  onChange={handleOnChange}
                  //showSelectionPreview={true}
                  moveRangeOnFirstSelection={true}
                  months={2}
                  ranges={rangeState}
                  direction="horizontal"
                />
              </Box>
              <Box className={`${classes.dateRangeActionButtons} calender-popup-filter`}>
                <Box>
                  <Typography
                    variant="caption"
                    className=""
                    display="block"
                  ></Typography>
                  <FormControl>
                    <FormLabel id="choose-date-range-type">
                      Filter based on:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="choose-date-range-type"
                      name="date-range-buttons-group"
                      onChange={filterDateTypeChange}
                      value={dateRangeFilterVal}
                      className="date-range-calender-radio"
                    >
                      <FormControlLabel
                        value="OpenDateTime"
                        control={<Radio />}
                        label="Opened Date"
                      />
                      <FormControlLabel
                        value="DueDateTime"
                        control={<Radio />}
                        label="Due Date"
                      />
                      <FormControlLabel
                        value="FirstResponseDueDateTime"
                        control={<Radio />}
                        label="First action date"
                      />
                      <FormControlLabel
                        value="CreatedDateTime"
                        control={<Radio />}
                        label="Updated date"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  <Button variant="outlined" onClick={handleCloseRangePicker}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.applyButton}
                    onClick={applyDate}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        }
      />
    </Box>
  );
});
const useStyles = makeStyles(() => ({
  dateInnerContainer: {
    display: "flex",
  },
  dateRangeActionButtons: {
    borderTop: "1px solid #ddd",
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    "& span": {
      lineHeight: 1,
      fontSize: "11px",
    },
  },
  applyButton: {
    backgroundColor: "#007bff",
    marginLeft: "9px",
    color: "white",
    "& span": {
      lineHeight: 1,
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  titleDate: {
    fontSize: 10,
  },
  icon: {
    width: 18,
    height: 18,
  },
}));
export default CalendarRangeOption;
