import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getWallboardByCode, getWallboardById } from "../../../redux/actions/Wallboard/Wallboard";
import { Box, Container, Grid, Typography } from "@mui/material";
import Breadcrumbs from "../../common2/Breadcrumbs";
import VerticalChart from "../../charts/horizontal-chart/VerticalChart";
import HorizontalChart from "../../charts/horizontal-chart/HorizontalChart";
import LineChart from "../../charts/line/LineChart";
import PieChart from "../../charts/pie/PieChart";
import DoughnutChart from "../../charts/dougnut/DoughnutChart";
import { IPieData } from "./InterfaceWallBoard";
import { alertMessage } from "../../../redux/actions/SystemIcons/systemIcons";
import { getTenantCodeBySubDomain } from "../../../redux/actions/Login/Login";
import HorizontalChartActual from "../../charts/horizontal-chart/HorizontalChartActual";

const ViewChart = () => {
    const param = useParams();
    const dispatch = useDispatch();
    const [viewVal, setViewVal] = useState<any>(null);
    const [viewValCode, setViewValCode] = useState<any>(null);
    const [wallBoardTypeList, setWallBoardTypeList] = useState<any[]>([]);
    const [chartsInitialized, setChartsInitialized] = useState<boolean>(false);
    const [externalWebLink, setExternalWebLink] = useState('');
    const [tenantCode, setTenantCode] = useState("")
    const CurrLocation = window.location.origin
    const subDomain = window.location.host.split('.')?.[0];
    // const subDomain = "simplisys.vizionsys.com".split('.')?.[0];


    const priorityName: any = {
        "P1": 409,
        "P2": 88,
        "P3": 60,
        "P4": 9,
    };

    const AgentData: any = {
        "Agent1": 9,
        "Agent2": 3,
        "Agent3": 5,
        "Agent4": 1,
    }

    const ticketStatus: any = {
        "Status1": 36,
        "Status2": 159,
        "Status3": 312,
        "Status4": 12,
    };

    const groupData: any = {
        "1st Line": 1,
        "2nd Line": 2,
        "3rd Line": 3,
        "4th Line": 4,
    }

    const ticketTypes: any = {
        "Type 1": 36,
        "Type 2": 81,
        "Type 3": 65,
        "Type 4": 80,
    }
    const contacts: any = {
        "Conatct 1": 36,
        "Conatct 2": 81,
        "Conatct 3": 65,
        "Conatct 4": 80,
    }
    const division: any = {
        "Division 1": 36,
        "Division 2": 81,
        "Division 3": 65,
        "Division 4": 80,
    }
    const department: any = {
        "Department 1": 36,
        "Department 2": 81,
        "Department 3": 65,
        "Department 4": 80,
    }
    const organisation: any = {
        "Organisation 1": 36,
        "Organisation 2": 81,
        "Organisation 3": 65,
        "Organisation 4": 80,
    }

    useEffect(() => {
        if (param?.code && tenantCode) {

            dispatch(getWallboardByCode(param.code, getWallboardByCodeCB, tenantCode))
        }
        if (param?.id) {
            dispatch(getWallboardById(param.id, getWallboardByIdCB))
        }
    }, [param?.id, tenantCode])
    useEffect(() => {
        if (param?.code) {
            console.log("subDomain",subDomain);
            
            if (subDomain && subDomain !== 'portal' && !subDomain.includes('localhost')) {
                dispatch(getTenantCodeBySubDomain(`${subDomain}`, TenantCodeCB));
            }
        }
    }, [param?.code])

    const TenantCodeCB =(resStatus: string, res: any)=>{
        if (resStatus === "0") {
            setTenantCode(res.tenantcode);
        }
    }
    const getWallboardByIdCB = (resStatus: string, res: any) => {
        console.log("getWallboardByIdCB", res, resStatus);
        if (resStatus === "0") {
            setViewVal(res);
        }
    };
    const getWallboardByCodeCB = (resStatus: string, res: any) => {
        console.log("getWallboardByIdCB", res, resStatus);
        if (resStatus === "0") {
            setViewValCode(res);
        }
    };

    useEffect(() => {

        if (viewVal?.wallBoardTypeList && !chartsInitialized) {
            const newWallBoardTypeList = viewVal.wallBoardTypeList.map((i: any) => {
                const { chartType, data,isActive } = i;
                if (!isActive) {
                    return null
                }
                if (chartType && data.datasets[0].label) {
                    let newData;
                    switch (data.datasets[0].label) {
                        case "Group":
                            newData = groupData;
                            break;
                        case "Priority":
                            newData = priorityName;
                            break;
                        case "TicketStatus":
                            newData = ticketStatus;
                            break;
                        case "Agent":
                            newData = AgentData;
                            break;
                        case "TicketType":
                            newData = ticketTypes;
                            break;
                        case "Department":
                            newData = department;
                            break;
                        case "ContactOrganisation":
                            newData = organisation;
                            break;
                        case "Division":
                            newData = division;
                            break;
                        case "Contacts":
                            newData = contacts;
                            break;
                        default:
                            newData = {};
                    }

                    const chart: any = renderChart(chartType, newData, data.datasets[0].label);
                    return { chartType: chartType, data: chart, isActive: true };
                }
                return null;
            })
            setWallBoardTypeList(newWallBoardTypeList);
            setChartsInitialized(true);
            setExternalWebLink(`${CurrLocation}/#/ViewChart/${viewVal.code}`)
        }
    }, [viewVal, chartsInitialized]);

    const extractData = (dataObject: any, value: any) => {
        if (dataObject && dataObject[value]) {
            return [dataObject[value].dataList, dataObject[value].count]
        }
        return [];
    };
    useEffect(() => {
        if (viewValCode?.wallBoardTypeList && !chartsInitialized) {
            const newWallBoardTypeList = viewValCode.wallBoardTypeList.map((i: any) => {
                const { chartType, value ,isActive} = i;
                if (!isActive) {
                    return null
                }
                if (chartType && value) {
                    const newData = extractData(i.wallboardData, value)

                    const chart = renderChart(chartType, newData[0], value);
                    return { chartType: chartType, data: chart, count: newData[1], isActive: true };
                }
                return null;
            })

            setWallBoardTypeList(newWallBoardTypeList);
            setChartsInitialized(true);
        }
    }, [viewValCode, chartsInitialized]);

    const renderChart = (type: any, data: any, property: string = "") => {
        const labels = Object.keys(data);
        const values = Object.values(data);
        const chartData: IPieData = {
            labels: labels,
            chartType: type,
            datasets: [
                {
                    label: property,
                    data: values,
                    backgroundColor: [
                        "rgba(75,192,192,0.2)",
                        "rgba(153,102,255,0.2)",
                        "rgba(255,159,64,0.2)",
                        "rgba(255,99,132,0.2)",
                        "rgba(54,162,235,0.2)",
                        "rgba(255,206,86,0.2)",
                    ],
                    borderColor: [
                        "rgba(75,192,192,1)",
                        "rgba(153,102,255,1)",
                        "rgba(255,159,64,1)",
                        "rgba(255,99,132,1)",
                        "rgba(54,162,235,1)",
                        "rgba(255,206,86,1)",
                    ],
                    borderWidth: 1,
                },
            ],
        };
        return chartData;
    }
    const ChartItem = useMemo(() => {
        const activeItems = wallBoardTypeList.filter(chartComponent => chartComponent?.isActive);
        const itemsToShow = activeItems.slice(0, 9);
        // console.log("itemsToShow",itemsToShow,itemsToShow.length);
        
        const getColNum = () => {
            if (itemsToShow.length === 1) {
                return 12;
            } else if (itemsToShow.length === 2 || itemsToShow.length === 3 || itemsToShow.length === 4 ) {
                return 6;
            }else if (itemsToShow.length === 5 || itemsToShow.length === 6 || itemsToShow.length === 7 || itemsToShow.length === 8 || itemsToShow.length === 9) {
                return 4;
            } else {
                return 12;
            }
        }
    
        return itemsToShow.map((chartComponent, index) => {
            const col = getColNum();
                let chartHeight;
                if (itemsToShow.length === 1 || itemsToShow.length === 2) {
                    chartHeight = 400;
                } else if (itemsToShow.length === 3 || itemsToShow.length === 4 || itemsToShow.length === 5 || itemsToShow.length === 6) {
                    chartHeight = 300;
                } else {
                    chartHeight = 200;
                }
            return (
                <Grid item md={col} key={index} style={{ padding: '20px', height: chartHeight }}>
                    {viewValCode &&
                    <div style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'bold' }}>
                       Count: {chartComponent.count}
                    </div>
                    }
                    {(() => {
                        switch (chartComponent?.chartType) {
                            case "VerticalBarGraph":
                                return <VerticalChart label={chartComponent?.data?.labels} dataSets={chartComponent.data.datasets} />;
                            case "HorizontalBarGraph":
                                return <HorizontalChartActual label={chartComponent?.data?.labels} dataSets={chartComponent.data.datasets} />;
                            case "LineGraph":
                                return <LineChart datasets={chartComponent.data.datasets} labels={chartComponent?.data?.labels} showLabels={false} />;
                            case "PieGraph":
                                return <PieChart labels={chartComponent?.data?.labels} datasets={chartComponent.data.datasets} showLabels={false} />;
                            case "DoughnutGraph":
                                return <DoughnutChart labels={chartComponent?.data?.labels} datasets={chartComponent.data.datasets} showLabels={false} />;
                            default:
                                return <>Choose dropdowns to render chart!</>;
                        }
                    })()}
                </Grid>
            );
        });
    }, [wallBoardTypeList, viewValCode]);
    
    const handleCopyLink = (e: any) => {
        e.preventDefault();
        if (externalWebLink) {
            navigator.clipboard.writeText(externalWebLink)
                .then(() => {
                    dispatch(alertMessage(`Success! External WallBoard Link Copied`, "success"))
                    console.log('Link copied to clipboard');
                })
                .catch((err) => {
                    dispatch(alertMessage(`Failed to Copy External WallBoard Link `, "error"))
                    console.error('Failed to copy link: ', err);
                });
        }
    };
    return (
        <>
            <div className="white-bg">
                <div>
                    {param.id &&
                        <Breadcrumbs
                            data={[
                                {
                                    title: 'Reports Management ',
                                    path: '/ReportsManagement'
                                },
                                {
                                    title: 'WallBoard',
                                    path: '/WallBoard'
                                },
                                {
                                    title: `View Wallboard`,
                                    path: `${"/ViewChart/" + param.id}`
                                }
                            ]}
                        />
                    }
                </div>
                <div style={{ marginTop: '10px' }}>
                    <Typography>
                        {viewVal && (
                            <a href="#" onClick={handleCopyLink}>
                                Copy External WallBoard Link here
                            </a>
                        )}
                    </Typography>
                </div>
            
           
            <Container className="white-bg p-2 mt-1 add-article">
                <Box className="p-1 white-bg" >

                    <Box className="white-bg p-2 mt-1 add-article">
                        <Typography>
                            {viewVal ? `WallBoard - ${viewVal?.name}` : `WallBoard - ${viewValCode?.name}`}
                        </Typography>
                        <Grid
                            container
                            spacing={1}
                            alignItems={"stretch"}
                            style={{
                                height: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            {ChartItem}
                        </Grid>

                    </Box>
                </Box>
            </Container>
            </div>

        </>
    );
};

export default ViewChart;
