import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toggleMobileFilter } from '../../../redux/actions/TicketsManagement/MobileTicketActions';
import { IState } from '../../../redux/reducers/rootReducers';
import BottomDrawer from '../MobileCommon/BottomDrawer';
import { useEffect, useState } from 'react';
import FormWrapper from './FormWrapper';
import CommonMobileDatePicker from '../MobileCommon/MobileDatePicker';
import { subWeeks, subYears } from 'date-fns';
import moment from 'moment';
import { storeFiltersValue } from '../../../redux/actions/TicketsManagement/GetTickets';
import CommonRadioGroup from '../MobileCommon/RadioGroup';
import { storeDashboardSummary } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { debounce } from 'lodash';
interface IFilterOptionProps {
    dispatch?:any;
    isOpened?:boolean;
    allResponses?:any;
    filtersVal?:any;
}
function MobileTicketFilter(props:IFilterOptionProps) {
    const {dispatch,isOpened=false,allResponses,filtersVal} = props;
    const [dateVal,setDateVal] = useState<any>({
        ...filtersVal.dateRange
    })
    const [tabIndex,setTabIndex] = useState(1)
    const closeFilterOption = () =>{
        dispatch(toggleMobileFilter(false));
    }
    const clearAll = () =>{
        dispatch(toggleMobileFilter(false));
        dispatch(storeFiltersValue(null,"UNSET_MOBILE_FILTERS"));
    }
    const toggleTab = (val:number)=>{
        setTabIndex(val);
    }
    useEffect(()=>{
        console.log("allResponses",allResponses)
    },[allResponses])
    useEffect(()=>{
        loadGroupSummary();
    },[])
    const loadGroupSummary = debounce(()=>{
        dispatch(storeDashboardSummary())
    },100)
    const submitFilterOption = (val:any) =>{
        console.log("submitFilterOption",val)
        dispatch(storeFiltersValue({
            dateRange:{
                ...dateVal,
                node: val.dateRangeProperty,
            },
            otherValues: val
        }
        , 'STORE_MOBILE_FILTERS_VALUE'));
        closeFilterOption();
    }
    const MobileDateRangePickerCB = (val:any) =>{
        console.log("MobileDateRangePickerCB",dateVal,val)
        setDateVal({
            ...dateVal,
            ...val
        })
    }
    return (
        <div>
            <BottomDrawer
                    isOpened ={isOpened}
                    onClose ={closeFilterOption}
                    title="Filter"
                    className='filter-drawer'
            >
                <Box>
                    <FormWrapper
                        onClose={clearAll}
                        onSubmit={submitFilterOption}
                        cancelLabel="Clear All"
                    >
                        <Box className="mobileFilterHolder">
                            <Box className="navs">
                                <Button className={`${tabIndex === 1 && "active"}`} onClick={()=>{toggleTab(1)}}>Date Range</Button>
                                <Button className={`${tabIndex === 2 && "active"}`} onClick={()=>{toggleTab(2)}}>Groups</Button>
                                <Button className={`${tabIndex === 3 && "active"}`} onClick={()=>{toggleTab(3)}}>Status</Button>
                                <Button className={`${tabIndex === 4 && "active"}`} onClick={()=>{toggleTab(4)}}>Type</Button>
                                <Button className={`${tabIndex === 5 && "active"}`} onClick={()=>{toggleTab(5)}}>Priority</Button>
                            </Box>
                            <Box className="filterItems">
                                    <Box className={`tab1 ${tabIndex !== 1 && "d-none"}`}>
                                        <MobileDateRangePicker callback={MobileDateRangePickerCB} initVal={filtersVal?.dateRange || {}}/>
                                    </Box>
                                    <Box className={`tab2 ${tabIndex !== 2 && "d-none"}`}><GroupCheckBox groups={allResponses?.groups || []} initVal={filtersVal?.otherValues}/></Box>
                                    <Box className={`tab3 ${tabIndex !== 3 && "d-none"}`}><CustomCheckBoxGroup keyVal="ticketStatus" list={allResponses?.ticketStatus || []} initVal={filtersVal?.otherValues}/></Box>
                                    <Box className={`tab4 ${tabIndex !== 4 && "d-none"}`}><CustomCheckBoxGroup keyVal="ticketType" list={allResponses?.TicketTypes || []} initVal={filtersVal?.otherValues}/></Box>
                                    <Box className={`tab5 ${tabIndex !== 5 && "d-none"}`}><CustomCheckBoxGroup keyVal="priority" list={allResponses?.priorities || []} initVal={filtersVal?.otherValues}/></Box>
                            </Box>
                        </Box>
                    </FormWrapper>
                </Box>
            </BottomDrawer>
        </div>
    )
}
const dateTypes = [
    {label : "Opened Date", value : "OpenDateTime"},
    {label : "Due Date", value : "DueDateTime"},
    {label : "First action date", value : "FirstResponseDueDateTime"},
    {label : "Updated date", value : "CreatedDateTime"}
];
const MobileDateRangePicker = ({initVal,callback}:any) =>{
    const [endMinDate,setEndMinDate] = useState(initVal?.startDate || new Date());
    const onChangeStartDate = (e:any)=>{
        console.log("setEndMinDate(e)",moment(e).toISOString())
        callback({startDate:moment(e).toDate()})
        setEndMinDate(e.toDate())
    }
    const onChangeEndDate = (e:any)=>{
        console.log("setEndMinDate(e)",moment(e).toISOString())
        callback({endDate:moment(e).toDate()})
    }
    return(
        <>
            <CommonMobileDatePicker
                label={"Start Date"}
                name='startDate'
                minDate={subYears(new Date(),2)}
                maxDate={new Date()}
                dynamicValue={initVal?.startDate || subWeeks(new Date(),4)}
                onChange={onChangeStartDate}

                // dynamicValue={prefillDueDate?.dueDate?.value}
            />
            <CommonMobileDatePicker
                label={"End Date"}
                name='endDate'
                minDate={endMinDate}
                dynamicValue={initVal?.endDate}
                onChange={onChangeEndDate}
            />


            <CommonRadioGroup
                id = "date-range-property"
                subHeading='Filter based on :'
                labelPlacement = {"start"}
                name = "dateRangeProperty"
                options = {dateTypes}
                defaultValue =  {initVal?.node || "OpenDateTime"}
            />
        </>
    )
}

const CustomCheckBoxGroup = (props:any) =>{
    const {list,keyVal,initVal}  = props;
    return(
        <>
            <FormGroup className='mobile-filter-list'>
                {list.map((i:any,index:number)=>{
                    return <FormControlLabel key={index} control={<Checkbox defaultChecked={initVal?.[`${keyVal}-${i?.id}`] ? true : false} />} value={i?.id} name={`${keyVal}-${i?.id}`} labelPlacement="start" label={i?.label || i?.name} />
                })}
            </FormGroup>
        </>
    )
}

const GroupCheckBox = (props:any)=>{
    const groupVals = useSelector((state:IState)=>state?.GroupInfoViewReducer?.groupVals?.group);
        
    const dispatch = useDispatch();
    
    useEffect(()=>{
        console.log("groupVals",groupVals)
    },[groupVals])
    const {groups,initVal} = props;
    return(
        <Box className="mobile-group-filter">
            {groups.map((i:any)=>{
                return (
                    <Box className="mobile-group-filter-item">
                        <Box className="title">
                            <Typography>{i?.name}</Typography>
                            <Typography className='count'>{groupVals?.[i?.name]?.count || 0}</Typography>
                        </Box>
                        <Box>
                            <FormGroup className='mobile-filter-list'>
                                <FormControlLabel control={<Checkbox defaultChecked={initVal?.[`group-${i?.id}-0`] ? true : false} />} value={`${i?.id}and0`} name={`group-${i?.id}-0`} labelPlacement="start" label={<span className='agentName'>Unassigned <span className="agentCount">{groupVals?.[i?.name]?.agentList?.["Unassigned"] || 0}</span></span>}/>
                                {i.agentsList.map((j:any,jindex:number)=>{
                                    return <FormControlLabel key={jindex} control={<Checkbox defaultChecked={initVal?.[`group-${i?.id}-${j?.id}`] ? true : false} />} value={`${i?.id}and${j?.id}`} name={`group-${i?.id}-${j?.id}`} labelPlacement="start" label={<span className='agentName'>{j?.label || j?.name} <span className="agentCount">{groupVals?.[i?.name]?.agentList?.[j.label] || 0}</span></span>}/>
                                })}
                                {i.agentsList.length === 0 && <Typography className="no-agents">No Agents Exist!</Typography>}
                            </FormGroup>
                        </Box>
                    </Box>)
            })}
        </Box>
    )
}

const mapStateToProps = (state:IState) => {
    return ({
        isOpened: state?.AllTicketsReducer?.mobileFilter,
        filtersVal : state?.mobileFiltersReducer,
        allResponses: state?.TicketsReducer?.allResponses,
    })
  };
  
export default connect(mapStateToProps)(MobileTicketFilter);