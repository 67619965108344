import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CustomSelect from "../../components/common/customSelect/CustomSelect";
import { IState } from "../../redux/reducers/rootReducers";

const colorPalatte: any = {
  New: "linear-gradient(90.13deg, #A855F7 0%, #6366F1 100%)",
  NewtoMe: "linear-gradient(90.13deg, #39BCF8 0%, #6365F1 100%)",
  InProgress: "linear-gradient(90deg, #F2994A 0%, #F2C94C 100%)",
  OnHold: "linear-gradient(90deg, #E62B19 0%, #F4067D 100%)",
  Pending: "linear-gradient(90deg, #FF9966 0%, #FF5E62 100%)",
  Resolved: "linear-gradient(90.13deg, #49DD81 0%, #22B4C6 100%)#3eb893",
  Closed: "linear-gradient(90.13deg, #49DD81 0%, #22B4C6 100%)#3eb893",
  Other: "linear-gradient(90.13deg, #A855F7 0%, #6366F1 100%)",
};

const StatusChart = ({
  formData,
  classes,
  setFormData,
}: {
  formData: any;
  classes: any;
  setFormData: any;
}) => {
  const allResponses:any = [];

  console.log("StatusChart",formData?.ticketStatus)
  return (
    <>
      <div className="top-filter"
        style={{
          width: "100%",
          padding: "10px 10px 10px 10px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
            gap: 10,
          }}
        >
          <CustomSelect
          
            isOpen={false}
            placeholder="Select a time range"
            width={"200px"}
            name="primaryContact"
            isColor={false}
            isDisabled={false}
            isError={false}
            defaultValue={formData?.timeFilterSatus}
            options={[
              {
                label: "All",
                id: 0,
                value: "All",
                initial: false,
              },
              {
                label: "Today",
                id: 1,
                value: "Today",
                initial: false,
              },
            ]}
            handleChange={(e: any) => {
              setFormData({ ...formData, timeFilterSatus: e });
            }}
            isMulti={false}
          />
           
        </div>
        {Object.keys(formData?.ticketStatus).length<=0 &&  
        <div style={{display:"flex", justifyContent:"center"}}>No data to display 🧐</div>
      
        }
      </div>

      <Box sx={{ width: "100%", margin: "10px", }}>
      {Object.keys(formData?.ticketStatus).length>0 &&<>
          <Grid
            container
            spacing={0}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {
              allResponses?.map && 
              allResponses.map(((i:any)=>{
                  return <>
                      <Grid item xs>
                        <Box className={classes.cardBox}>
                          <Typography>{i?.displayName}</Typography>  
                          <Typography className="count">{formData?.ticketStatus[i?.displayName]}</Typography>  
                        </Box>
                      </Grid>
                      {/* displayName {i.displayName} */}
                  </>
              }))
            }
            {/* {Object.keys(formData?.ticketStatus).map((ele: string) => {
              return (
                <Grid item xs>
                  <Box className={classes.cardBox}>
                    <Typography>{ele}</Typography>  
                    <Typography className="count">{formData?.ticketStatus[ele]}</Typography>  
                  </Box>
                </Grid>
              );
            })} */}
          </Grid>

        {/* <Masonry columns={{}} spacing={{ xs: 2, sm: 2, md: 3 }}>
          {Object.keys(formData?.ticketStatus).map((ele: string) => {
            return (
              <CustomCard
                className={classes.card}
                prefixIcon={
                  <ConfirmationNumberOutlinedIcon style={{ color: "white" }} />
                }
                headerColor={colorPalatte[ele] ? colorPalatte[ele] : colorPalatte["Other"]}
                height="auto"
                width={"200px"}
                header={
                  <div style={{ fontSize: "12px", color: "white" }}>{ele}</div>
                }
                subHeader={
                  <div style={{ fontSize: "40px", color: "white" }}>
                    {formData?.ticketStatus[ele]}
                  </div>
                }
              />
            );
          })}

          
        </Masonry> */}
        </>
      }
      </Box>

    </>
  );
};
export default StatusChart;
